// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: health.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { UpdateBoolean, UpdateClearableString } from "./base";

export const protobufPackage = "hiber.health";

/**
 * A health level in an organization.
 * Health can be customized depending on your need.
 *
 * The default health levels are:
 * - OK (green): no problems detected
 * - WARNING (orange): unresolvable problems detected, for example delayed or skipped messages
 * - ERROR (red): significant problems detected (that typically can be resolved),
 *   for example inactivity or invalid messages (resolved on a successful message)
 *
 * Health levels can be customized to as many as you need for your operations, for example:
 * - INTERVENTION
 * - DEFECT
 * - BATTERY
 * - HIGH
 * - LOW
 *
 * Health levels are ordered by severity (low to high),
 * and of all things affecting a modem's health,
 * only the most severe level will be returned when retrieving a modem.
 *
 * Health can be assigned using modems alarms, which specify the health level they will cause on a modem (and for how
 * long, if it does not resolve automatically).
 *
 * Precisely one health level can be assigned as a catch-all for any unknown health levels from alarms (or Hiber systems),
 * which can happen when a device manufacturer has provided alarms to your device (e.g. a low battery alarm).
 * By default, any unknown health levels map to the level that is marked catch-all.
 *
 * Health level have a set of named colors, represented by a map where the key is the name of the color
 * and the value is a string that represents a valid CSS3 color.
 * Simple examples are: green, red, orange, grey, #FF00FF for fuchsia, etc (Keep in mind that CSS3 allows for many
 * ways to define colors, see https://www.w3.org/TR/2003/WD-css3-color-20030214/).
 *
 * All the following definitions also mean "red":
 *  - rgb(255, 0, 0)
 *  - rgb(100%, 0, 0)
 *  - rgba(100%, 0%, 0%, 100%)
 *  - hsl(0, 100%, 50%)
 *  - hsla(0, 100%, 50%, 1)
 *
 * The client is responsible for rendering the correct color from the CSS3 color-space and for setting the colors and
 * their names. There is no verification on missing named colors, so the client must set sensible defaults when colors
 * are missing.
 *
 * To assist with sorting, health levels have a numeric severity equal to their index in the sorted list of health
 * levels (starting at 1). This means higher numbers denote a more severe health.
 * Since these values are noting more than a list index, they should not be cached, compared to another organization or
 * compared to values retrieved from the API at another time.
 *
 * For example, an organization using the default health would have:
 * - Ok: severity 1
 * - Warning: severity 2
 * - Error: severity 3
 *
 * That organization could then add a new health level in between Ok and Warning, meaning the severity of Warning and
 * Error will change:
 * - Ok, severity 1
 * - ItsComplicated, severity 2
 * - Warning, severity 3
 * - Error, severity 4
 */
export interface HealthLevel {
  /**
   * The name of this health level.
   * Levels are identified by their name.
   * The API does support renaming, where the rename is propagated to all the relevant parts of the system.
   */
  level: string;
  /**
   * Default color for the health level, as a string that represents a valid CSS3 color.
   * DEPRECATED: Maps to the color named "text" in color_data.
   *
   * @deprecated
   */
  color: string;
  /** Map of named colors, where key is the name and the value is a valid CSS3 color definition. */
  colorData: { [key: string]: string };
  /**
   * A unique numeric value equal to the index of this health level in the list of health levels sorted by ascending severity (starting at 1).
   * This means higher numbers denote a more severe health.
   * This value cannot be used when creating or updating.
   * To change the severity for a health level, reorder all health levels.
   */
  severity: number;
  /**
   * Precisely one health level can be assigned as a catch-all for any unknown health levels from alarms (or Hiber systems),
   * which can happen when a device manufacturer has provided alarms for your device (e.g. a low battery alarm).
   * By default, unknown health levels map to the level of the highest severity,
   * unless another level is marked as catch-all.
   */
  catchAll: boolean;
}

export interface HealthLevel_ColorDataEntry {
  key: string;
  value: string;
}

export interface HealthLevelSelection {
  /** Search for the given string in the levels and colors. */
  search?:
    | string
    | undefined;
  /** Filter by exact levels. */
  levels: string[];
}

export interface ListHealthLevels {
}

export interface ListHealthLevels_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the health levels to list. Optional, when omitted or empty everything is included. */
  selection?: HealthLevelSelection | undefined;
}

export interface ListHealthLevels_Response {
  healthLevels: HealthLevel[];
  request: ListHealthLevels_Request | undefined;
}

/** Re-order the health levels for your organization. */
export interface ReorderHealthLevels {
}

export interface ReorderHealthLevels_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * The health levels for the organization, ordered by severity (low to high).
   * This list must include _all_ health levels, or the call will fail.
   * The implication is, that if someone adds, removes or renames a level just before this call is sent,
   * then this call will fail to protect against strange results.
   */
  levels: string[];
}

export interface ReorderHealthLevels_Response {
  reorderedHealthLevels: HealthLevel[];
}

export interface AddHealthLevel {
}

export interface AddHealthLevel_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * Add the given level to the organizations health levels.
   * The new health level will be added with the highest severity (at the bottom of the health levels list).
   */
  newHealthLevel: HealthLevel | undefined;
}

export interface AddHealthLevel_Response {
  healthLevels: HealthLevel[];
}

export interface UpdateHealthLevel {
}

export interface UpdateHealthLevel_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  level: string;
  /**
   * Optionally, rename this health level.
   * NOTE! Don't use this to change the semantics of a health level.
   * All historic events that have this health-level will change with it.
   * (Unless you are very sure you want to change history, that is)
   */
  rename?:
    | string
    | undefined;
  /**
   * Optionally, set or update the color for this health level.
   * DEPRECATED, use add_colors with name "text".
   * Vice versa, this field will also update the "text" color in the color_data field of HealthLevel.
   *
   * @deprecated
   */
  updateColor?:
    | UpdateClearableString
    | undefined;
  /** Remove colors by name. Will remove the named color from the color_data field in HealthLevel. */
  removeColors: string[];
  /**
   * Add colors by name. Will add the named color from the color_data field in HealthLevel.
   * For backwards compatibility reasons, if add_colors contains a color named "text", it will also update
   * the field color in HealthLevel
   */
  addColors: { [key: string]: string };
  /**
   * Optionally, set or unset the catch all flag.
   *
   * @deprecated
   */
  deprecatedUpdateCatchAll:
    | UpdateBoolean
    | undefined;
  /** Optionally, set or unset the catch all flag. */
  updateCatchAll?: boolean | undefined;
}

export interface UpdateHealthLevel_Request_AddColorsEntry {
  key: string;
  value: string;
}

export interface UpdateHealthLevel_Response {
  healthLevels: HealthLevel[];
}

export interface RemoveHealthLevel {
}

export interface RemoveHealthLevel_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  level: string;
}

export interface RemoveHealthLevel_Response {
  healthLevels: HealthLevel[];
}

function createBaseHealthLevel(): HealthLevel {
  return { level: "", color: "", colorData: {}, severity: 0, catchAll: false };
}

export const HealthLevel = {
  encode(message: HealthLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.level !== "") {
      writer.uint32(10).string(message.level);
    }
    if (message.color !== "") {
      writer.uint32(18).string(message.color);
    }
    Object.entries(message.colorData).forEach(([key, value]) => {
      HealthLevel_ColorDataEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
    });
    if (message.severity !== 0) {
      writer.uint32(40).int64(message.severity);
    }
    if (message.catchAll !== false) {
      writer.uint32(24).bool(message.catchAll);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HealthLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.level = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.color = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = HealthLevel_ColorDataEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.colorData[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.severity = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.catchAll = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HealthLevel>): HealthLevel {
    return HealthLevel.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<HealthLevel>): HealthLevel {
    const message = createBaseHealthLevel();
    message.level = object.level ?? "";
    message.color = object.color ?? "";
    message.colorData = Object.entries(object.colorData ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.severity = object.severity ?? 0;
    message.catchAll = object.catchAll ?? false;
    return message;
  },
};

function createBaseHealthLevel_ColorDataEntry(): HealthLevel_ColorDataEntry {
  return { key: "", value: "" };
}

export const HealthLevel_ColorDataEntry = {
  encode(message: HealthLevel_ColorDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HealthLevel_ColorDataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLevel_ColorDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HealthLevel_ColorDataEntry>): HealthLevel_ColorDataEntry {
    return HealthLevel_ColorDataEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<HealthLevel_ColorDataEntry>): HealthLevel_ColorDataEntry {
    const message = createBaseHealthLevel_ColorDataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseHealthLevelSelection(): HealthLevelSelection {
  return { search: undefined, levels: [] };
}

export const HealthLevelSelection = {
  encode(message: HealthLevelSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    for (const v of message.levels) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HealthLevelSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHealthLevelSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.levels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HealthLevelSelection>): HealthLevelSelection {
    return HealthLevelSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<HealthLevelSelection>): HealthLevelSelection {
    const message = createBaseHealthLevelSelection();
    message.search = object.search ?? undefined;
    message.levels = object.levels?.map((e) => e) || [];
    return message;
  },
};

function createBaseListHealthLevels(): ListHealthLevels {
  return {};
}

export const ListHealthLevels = {
  encode(_: ListHealthLevels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListHealthLevels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListHealthLevels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListHealthLevels>): ListHealthLevels {
    return ListHealthLevels.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListHealthLevels>): ListHealthLevels {
    const message = createBaseListHealthLevels();
    return message;
  },
};

function createBaseListHealthLevels_Request(): ListHealthLevels_Request {
  return { organization: undefined, selection: undefined };
}

export const ListHealthLevels_Request = {
  encode(message: ListHealthLevels_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      HealthLevelSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListHealthLevels_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListHealthLevels_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = HealthLevelSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListHealthLevels_Request>): ListHealthLevels_Request {
    return ListHealthLevels_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListHealthLevels_Request>): ListHealthLevels_Request {
    const message = createBaseListHealthLevels_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? HealthLevelSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseListHealthLevels_Response(): ListHealthLevels_Response {
  return { healthLevels: [], request: undefined };
}

export const ListHealthLevels_Response = {
  encode(message: ListHealthLevels_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.healthLevels) {
      HealthLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListHealthLevels_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListHealthLevels_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListHealthLevels_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.healthLevels.push(HealthLevel.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListHealthLevels_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListHealthLevels_Response>): ListHealthLevels_Response {
    return ListHealthLevels_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListHealthLevels_Response>): ListHealthLevels_Response {
    const message = createBaseListHealthLevels_Response();
    message.healthLevels = object.healthLevels?.map((e) => HealthLevel.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListHealthLevels_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseReorderHealthLevels(): ReorderHealthLevels {
  return {};
}

export const ReorderHealthLevels = {
  encode(_: ReorderHealthLevels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReorderHealthLevels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReorderHealthLevels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ReorderHealthLevels>): ReorderHealthLevels {
    return ReorderHealthLevels.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ReorderHealthLevels>): ReorderHealthLevels {
    const message = createBaseReorderHealthLevels();
    return message;
  },
};

function createBaseReorderHealthLevels_Request(): ReorderHealthLevels_Request {
  return { organization: undefined, levels: [] };
}

export const ReorderHealthLevels_Request = {
  encode(message: ReorderHealthLevels_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.levels) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReorderHealthLevels_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReorderHealthLevels_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.levels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ReorderHealthLevels_Request>): ReorderHealthLevels_Request {
    return ReorderHealthLevels_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ReorderHealthLevels_Request>): ReorderHealthLevels_Request {
    const message = createBaseReorderHealthLevels_Request();
    message.organization = object.organization ?? undefined;
    message.levels = object.levels?.map((e) => e) || [];
    return message;
  },
};

function createBaseReorderHealthLevels_Response(): ReorderHealthLevels_Response {
  return { reorderedHealthLevels: [] };
}

export const ReorderHealthLevels_Response = {
  encode(message: ReorderHealthLevels_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reorderedHealthLevels) {
      HealthLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReorderHealthLevels_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReorderHealthLevels_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reorderedHealthLevels.push(HealthLevel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ReorderHealthLevels_Response>): ReorderHealthLevels_Response {
    return ReorderHealthLevels_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ReorderHealthLevels_Response>): ReorderHealthLevels_Response {
    const message = createBaseReorderHealthLevels_Response();
    message.reorderedHealthLevels = object.reorderedHealthLevels?.map((e) => HealthLevel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddHealthLevel(): AddHealthLevel {
  return {};
}

export const AddHealthLevel = {
  encode(_: AddHealthLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddHealthLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddHealthLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AddHealthLevel>): AddHealthLevel {
    return AddHealthLevel.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AddHealthLevel>): AddHealthLevel {
    const message = createBaseAddHealthLevel();
    return message;
  },
};

function createBaseAddHealthLevel_Request(): AddHealthLevel_Request {
  return { organization: undefined, newHealthLevel: undefined };
}

export const AddHealthLevel_Request = {
  encode(message: AddHealthLevel_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.newHealthLevel !== undefined) {
      HealthLevel.encode(message.newHealthLevel, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddHealthLevel_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddHealthLevel_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newHealthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AddHealthLevel_Request>): AddHealthLevel_Request {
    return AddHealthLevel_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AddHealthLevel_Request>): AddHealthLevel_Request {
    const message = createBaseAddHealthLevel_Request();
    message.organization = object.organization ?? undefined;
    message.newHealthLevel = (object.newHealthLevel !== undefined && object.newHealthLevel !== null)
      ? HealthLevel.fromPartial(object.newHealthLevel)
      : undefined;
    return message;
  },
};

function createBaseAddHealthLevel_Response(): AddHealthLevel_Response {
  return { healthLevels: [] };
}

export const AddHealthLevel_Response = {
  encode(message: AddHealthLevel_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.healthLevels) {
      HealthLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddHealthLevel_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddHealthLevel_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.healthLevels.push(HealthLevel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AddHealthLevel_Response>): AddHealthLevel_Response {
    return AddHealthLevel_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AddHealthLevel_Response>): AddHealthLevel_Response {
    const message = createBaseAddHealthLevel_Response();
    message.healthLevels = object.healthLevels?.map((e) => HealthLevel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateHealthLevel(): UpdateHealthLevel {
  return {};
}

export const UpdateHealthLevel = {
  encode(_: UpdateHealthLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateHealthLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateHealthLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateHealthLevel>): UpdateHealthLevel {
    return UpdateHealthLevel.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateHealthLevel>): UpdateHealthLevel {
    const message = createBaseUpdateHealthLevel();
    return message;
  },
};

function createBaseUpdateHealthLevel_Request(): UpdateHealthLevel_Request {
  return {
    organization: undefined,
    level: "",
    rename: undefined,
    updateColor: undefined,
    removeColors: [],
    addColors: {},
    deprecatedUpdateCatchAll: undefined,
    updateCatchAll: undefined,
  };
}

export const UpdateHealthLevel_Request = {
  encode(message: UpdateHealthLevel_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.level !== "") {
      writer.uint32(18).string(message.level);
    }
    if (message.rename !== undefined) {
      writer.uint32(26).string(message.rename);
    }
    if (message.updateColor !== undefined) {
      UpdateClearableString.encode(message.updateColor, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.removeColors) {
      writer.uint32(58).string(v!);
    }
    Object.entries(message.addColors).forEach(([key, value]) => {
      UpdateHealthLevel_Request_AddColorsEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    if (message.deprecatedUpdateCatchAll !== undefined) {
      UpdateBoolean.encode(message.deprecatedUpdateCatchAll, writer.uint32(42).fork()).ldelim();
    }
    if (message.updateCatchAll !== undefined) {
      writer.uint32(64).bool(message.updateCatchAll);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateHealthLevel_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateHealthLevel_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.level = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rename = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateColor = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.removeColors.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = UpdateHealthLevel_Request_AddColorsEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.addColors[entry6.key] = entry6.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedUpdateCatchAll = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.updateCatchAll = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateHealthLevel_Request>): UpdateHealthLevel_Request {
    return UpdateHealthLevel_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateHealthLevel_Request>): UpdateHealthLevel_Request {
    const message = createBaseUpdateHealthLevel_Request();
    message.organization = object.organization ?? undefined;
    message.level = object.level ?? "";
    message.rename = object.rename ?? undefined;
    message.updateColor = (object.updateColor !== undefined && object.updateColor !== null)
      ? UpdateClearableString.fromPartial(object.updateColor)
      : undefined;
    message.removeColors = object.removeColors?.map((e) => e) || [];
    message.addColors = Object.entries(object.addColors ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.deprecatedUpdateCatchAll =
      (object.deprecatedUpdateCatchAll !== undefined && object.deprecatedUpdateCatchAll !== null)
        ? UpdateBoolean.fromPartial(object.deprecatedUpdateCatchAll)
        : undefined;
    message.updateCatchAll = object.updateCatchAll ?? undefined;
    return message;
  },
};

function createBaseUpdateHealthLevel_Request_AddColorsEntry(): UpdateHealthLevel_Request_AddColorsEntry {
  return { key: "", value: "" };
}

export const UpdateHealthLevel_Request_AddColorsEntry = {
  encode(message: UpdateHealthLevel_Request_AddColorsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateHealthLevel_Request_AddColorsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateHealthLevel_Request_AddColorsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateHealthLevel_Request_AddColorsEntry>): UpdateHealthLevel_Request_AddColorsEntry {
    return UpdateHealthLevel_Request_AddColorsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateHealthLevel_Request_AddColorsEntry>): UpdateHealthLevel_Request_AddColorsEntry {
    const message = createBaseUpdateHealthLevel_Request_AddColorsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateHealthLevel_Response(): UpdateHealthLevel_Response {
  return { healthLevels: [] };
}

export const UpdateHealthLevel_Response = {
  encode(message: UpdateHealthLevel_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.healthLevels) {
      HealthLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateHealthLevel_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateHealthLevel_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.healthLevels.push(HealthLevel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateHealthLevel_Response>): UpdateHealthLevel_Response {
    return UpdateHealthLevel_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateHealthLevel_Response>): UpdateHealthLevel_Response {
    const message = createBaseUpdateHealthLevel_Response();
    message.healthLevels = object.healthLevels?.map((e) => HealthLevel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRemoveHealthLevel(): RemoveHealthLevel {
  return {};
}

export const RemoveHealthLevel = {
  encode(_: RemoveHealthLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveHealthLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveHealthLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveHealthLevel>): RemoveHealthLevel {
    return RemoveHealthLevel.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RemoveHealthLevel>): RemoveHealthLevel {
    const message = createBaseRemoveHealthLevel();
    return message;
  },
};

function createBaseRemoveHealthLevel_Request(): RemoveHealthLevel_Request {
  return { organization: undefined, level: "" };
}

export const RemoveHealthLevel_Request = {
  encode(message: RemoveHealthLevel_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.level !== "") {
      writer.uint32(18).string(message.level);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveHealthLevel_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveHealthLevel_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.level = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveHealthLevel_Request>): RemoveHealthLevel_Request {
    return RemoveHealthLevel_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemoveHealthLevel_Request>): RemoveHealthLevel_Request {
    const message = createBaseRemoveHealthLevel_Request();
    message.organization = object.organization ?? undefined;
    message.level = object.level ?? "";
    return message;
  },
};

function createBaseRemoveHealthLevel_Response(): RemoveHealthLevel_Response {
  return { healthLevels: [] };
}

export const RemoveHealthLevel_Response = {
  encode(message: RemoveHealthLevel_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.healthLevels) {
      HealthLevel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveHealthLevel_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveHealthLevel_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.healthLevels.push(HealthLevel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveHealthLevel_Response>): RemoveHealthLevel_Response {
    return RemoveHealthLevel_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemoveHealthLevel_Response>): RemoveHealthLevel_Response {
    const message = createBaseRemoveHealthLevel_Response();
    message.healthLevels = object.healthLevels?.map((e) => HealthLevel.fromPartial(e)) || [];
    return message;
  },
};

export type HealthServiceDefinition = typeof HealthServiceDefinition;
export const HealthServiceDefinition = {
  name: "HealthService",
  fullName: "hiber.health.HealthService",
  methods: {
    /** List the health levels. */
    list: {
      name: "List",
      requestType: ListHealthLevels_Request,
      requestStream: false,
      responseType: ListHealthLevels_Response,
      responseStream: false,
      options: {},
    },
    /** Reorder the health levels, changing the order of severity. */
    reorder: {
      name: "Reorder",
      requestType: ReorderHealthLevels_Request,
      requestStream: false,
      responseType: ReorderHealthLevels_Response,
      responseStream: false,
      options: {},
    },
    /** Add a new health level at the end of the list (highest severity). */
    add: {
      name: "Add",
      requestType: AddHealthLevel_Request,
      requestStream: false,
      responseType: AddHealthLevel_Response,
      responseStream: false,
      options: {},
    },
    /** Update or rename a health level. */
    update: {
      name: "Update",
      requestType: UpdateHealthLevel_Request,
      requestStream: false,
      responseType: UpdateHealthLevel_Response,
      responseStream: false,
      options: {},
    },
    /** Remove a health level. Any events with that level are redirected to the catch-all level. */
    remove: {
      name: "Remove",
      requestType: RemoveHealthLevel_Request,
      requestStream: false,
      responseType: RemoveHealthLevel_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
