// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: base.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration as Duration1 } from "./google/protobuf/duration";
import { Timestamp as Timestamp2 } from "./google/protobuf/timestamp";
import {
  OrganizationPermission,
  organizationPermissionFromJSON,
  organizationPermissionToNumber,
  SupportPermission,
  supportPermissionFromJSON,
  supportPermissionToNumber,
  UserPermission,
  userPermissionFromJSON,
  userPermissionToNumber,
} from "./permission";

export const protobufPackage = "hiber";

/**
 * Enum of api-accessible events.
 * The event types in this enum have a protobuf implementation, and can be used, for example, in the
 * api event stream and publishers.
 */
export enum EventType {
  DEFAULT = "DEFAULT",
  /** ORGANIZATION_CREATED - A new organization was created under your organization. */
  ORGANIZATION_CREATED = "ORGANIZATION_CREATED",
  /**
   * ORGANIZATION_UPDATED - Your organization information was updated.
   * This deals with things like display name and contact information, not users and devices.
   */
  ORGANIZATION_UPDATED = "ORGANIZATION_UPDATED",
  /** ORGANIZATION_DELETED - An organization under your organization was deleted. */
  ORGANIZATION_DELETED = "ORGANIZATION_DELETED",
  /**
   * ORGANIZATION_EVENT_CONFIGURATION_UPDATED - Your organization's event configuration was updated.
   * This refers to things like message summary configuration.
   */
  ORGANIZATION_EVENT_CONFIGURATION_UPDATED = "ORGANIZATION_EVENT_CONFIGURATION_UPDATED",
  /** ASSET_CREATED - A new asset was created in your organization. */
  ASSET_CREATED = "ASSET_CREATED",
  /** ASSET_UPDATED - An asset in your organization was updated (i.e. renamed, tagged). */
  ASSET_UPDATED = "ASSET_UPDATED",
  /** ASSET_DELETED - An asset in your organization was deleted. */
  ASSET_DELETED = "ASSET_DELETED",
  /** DEVICE_CREATED - A new device was created in your organization, either manually or by a gateway. */
  DEVICE_CREATED = "DEVICE_CREATED",
  /** DEVICE_UPDATED - A device in your organization was manually updated (i.e. renamed, tagged). */
  DEVICE_UPDATED = "DEVICE_UPDATED",
  /** DEVICE_LOCATION_UPDATED - The location of a device in your organization was updated, either manually or by a message. */
  DEVICE_LOCATION_UPDATED = "DEVICE_LOCATION_UPDATED",
  /** DEVICE_INSTALLED - A device in your organization was installed and should now be active. */
  DEVICE_INSTALLED = "DEVICE_INSTALLED",
  /**
   * MESSAGE_RECEIVED - A device in your organization sent a message.
   * This event is the final output for the message, after any parsing and post-processing.
   */
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  /**
   * MESSAGE_BODY_PARSED - A device in your organization sent a message, and it was parsed by a message body parser.
   * For the final event, with all applied parsers and post processing, use MESSAGE_RECEIVED instead.
   */
  MESSAGE_BODY_PARSED = "MESSAGE_BODY_PARSED",
  /**
   * MESSAGE_BODY_RECEIVED - A device in your organization sent a message, and it has been scheduled for parsing by message body parsers
   * and post-processing.
   * For the final event, with all applied parsers and post processing, use MESSAGE_RECEIVED instead.
   */
  MESSAGE_BODY_RECEIVED = "MESSAGE_BODY_RECEIVED",
  /**
   * MESSAGE_CANNOT_BE_PARSED - A device in your organization sent a message, and it could not be parsed by any assigned message body parser.
   * This is typically a configuration issue. Please contact customer support if this is not resolved.
   */
  MESSAGE_CANNOT_BE_PARSED = "MESSAGE_CANNOT_BE_PARSED",
  /** MESSAGE_SUMMARY - A summary of messages in your organization was created, based on your event configuration. */
  MESSAGE_SUMMARY = "MESSAGE_SUMMARY",
  /**
   * MESSAGE_BODY_PARSER_CREATED - A new message body parser was created in your organization.
   * This typically only happens for custom solutions.
   */
  MESSAGE_BODY_PARSER_CREATED = "MESSAGE_BODY_PARSER_CREATED",
  /**
   * MESSAGE_BODY_PARSER_UPDATED - A message body parser in your organization was updated.
   * This typically only happens for custom solutions.
   */
  MESSAGE_BODY_PARSER_UPDATED = "MESSAGE_BODY_PARSER_UPDATED",
  /**
   * MESSAGE_BODY_PARSER_DELETED - A message body parser in your organization was deleted.
   * This typically only happens for custom solutions.
   */
  MESSAGE_BODY_PARSER_DELETED = "MESSAGE_BODY_PARSER_DELETED",
  /**
   * ALARM_TRIGGERED - An alarm was triggered in your organizations.
   * Depending on the alarm, this may mean that a device sent a message with a value (e.g. pressure)
   * outside of the expected range, or moved out of the expected area.
   */
  ALARM_TRIGGERED = "ALARM_TRIGGERED",
  /** ALARM_CREATED - A new alarm was created in your organization. */
  ALARM_CREATED = "ALARM_CREATED",
  /** ALARM_UPDATED - An alarm in your organization was updated. */
  ALARM_UPDATED = "ALARM_UPDATED",
  /** ALARM_DELETED - An alarm in your organization was deleted. */
  ALARM_DELETED = "ALARM_DELETED",
  /**
   * ASSIGNED - An assignment was made in your organization.
   * For example: assigning a tag or alarm to a modem.
   */
  ASSIGNED = "ASSIGNED",
  /**
   * UNASSIGNED - An assignment was removed in your organization.
   * For example: removing a tag or alarm from a modem.
   */
  UNASSIGNED = "UNASSIGNED",
  /**
   * TRANSFER - A device was transferred into or out of your organization.
   * Transferred device lose their data and are only used in the new organization if connected to a gateway in that
   * organization.
   */
  TRANSFER = "TRANSFER",
  /**
   * PUBLISHER_CREATED - A new publisher was created in your organization.
   * Publishers are any system that pushes data out of the system, like webhooks, MQTT integrations and
   * even email preferences.
   */
  PUBLISHER_CREATED = "PUBLISHER_CREATED",
  /**
   * PUBLISHER_UPDATED - A publisher in your organization was updated.
   * Publishers are any system that pushes data out of the system, like webhooks, MQTT integrations and
   * even email preferences.
   */
  PUBLISHER_UPDATED = "PUBLISHER_UPDATED",
  /**
   * PUBLISHER_DELETED - A publisher in your organization was deleted.
   * Publishers are any system that pushes data out of the system, like webhooks, MQTT integrations and
   * even email preferences.
   */
  PUBLISHER_DELETED = "PUBLISHER_DELETED",
  /**
   * PUBLISHER_FAILED - A publisher in your organization failed to send its data.
   * This can be a temporary issue (perhaps the webhook endpoint is down) or an indication of a configuration issue.
   * Publishers are any system that pushes data out of the system, like webhooks, MQTT integrations and
   * even email preferences.
   */
  PUBLISHER_FAILED = "PUBLISHER_FAILED",
  /**
   * PUBLISHER_AUTO_DISABLED - A publisher in your organization failed to send its data for a long enough period that is has been disabled.
   * This means the failures were not a temporary issue, but there is something wrong with the configuration.
   * Publishers are any system that pushes data out of the system, like webhooks, MQTT integrations and
   * even email preferences.
   */
  PUBLISHER_AUTO_DISABLED = "PUBLISHER_AUTO_DISABLED",
  /** USER_ACCESS_REQUEST - A user has requested access to your organization. You can review their request and approve or reject them. */
  USER_ACCESS_REQUEST = "USER_ACCESS_REQUEST",
  /** USER_INVITED - A user was invited into your organization. They can review your invite and accept it or ignore it. */
  USER_INVITED = "USER_INVITED",
  /** USER_ADDED - A user was granted access to your organization, by request, invite, or created by an organization admin. */
  USER_ADDED = "USER_ADDED",
  /** USER_REMOVED - A user was removed from your organization by an organization admin. */
  USER_REMOVED = "USER_REMOVED",
  /** USER_VALIDATION_UPDATED - The user validation (i.e. email domain) for your organization was updated. */
  USER_VALIDATION_UPDATED = "USER_VALIDATION_UPDATED",
  /** TOKEN_CREATED - A new token was created for your organization. */
  TOKEN_CREATED = "TOKEN_CREATED",
  /** TOKEN_EXPIRY_WARNING - A token in your organization will expire within 2 weeks. */
  TOKEN_EXPIRY_WARNING = "TOKEN_EXPIRY_WARNING",
  /** TOKEN_EXPIRED - A token in your organization has expired. */
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  /** TOKEN_DELETED - A token in your organization was deleted. */
  TOKEN_DELETED = "TOKEN_DELETED",
  /** EXPORT_CREATED - A new export was started for your organization, exporting data (i.e. messages) to a file. */
  EXPORT_CREATED = "EXPORT_CREATED",
  /**
   * EXPORT_READY - An export in your organization has completed and the resulting file with data (i.e. messages as CSV)
   * is ready to be downloaded.
   */
  EXPORT_READY = "EXPORT_READY",
  /** EXPORT_FAILED - An export in your organization has failed (typically because of incorrect data selection). */
  EXPORT_FAILED = "EXPORT_FAILED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function eventTypeFromJSON(object: any): EventType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return EventType.DEFAULT;
    case 34:
    case "ORGANIZATION_CREATED":
      return EventType.ORGANIZATION_CREATED;
    case 12:
    case "ORGANIZATION_UPDATED":
      return EventType.ORGANIZATION_UPDATED;
    case 35:
    case "ORGANIZATION_DELETED":
      return EventType.ORGANIZATION_DELETED;
    case 43:
    case "ORGANIZATION_EVENT_CONFIGURATION_UPDATED":
      return EventType.ORGANIZATION_EVENT_CONFIGURATION_UPDATED;
    case 70:
    case "ASSET_CREATED":
      return EventType.ASSET_CREATED;
    case 71:
    case "ASSET_UPDATED":
      return EventType.ASSET_UPDATED;
    case 72:
    case "ASSET_DELETED":
      return EventType.ASSET_DELETED;
    case 55:
    case "DEVICE_CREATED":
      return EventType.DEVICE_CREATED;
    case 36:
    case "DEVICE_UPDATED":
      return EventType.DEVICE_UPDATED;
    case 4:
    case "DEVICE_LOCATION_UPDATED":
      return EventType.DEVICE_LOCATION_UPDATED;
    case 33:
    case "DEVICE_INSTALLED":
      return EventType.DEVICE_INSTALLED;
    case 5:
    case "MESSAGE_RECEIVED":
      return EventType.MESSAGE_RECEIVED;
    case 39:
    case "MESSAGE_BODY_PARSED":
      return EventType.MESSAGE_BODY_PARSED;
    case 45:
    case "MESSAGE_BODY_RECEIVED":
      return EventType.MESSAGE_BODY_RECEIVED;
    case 15:
    case "MESSAGE_CANNOT_BE_PARSED":
      return EventType.MESSAGE_CANNOT_BE_PARSED;
    case 42:
    case "MESSAGE_SUMMARY":
      return EventType.MESSAGE_SUMMARY;
    case 46:
    case "MESSAGE_BODY_PARSER_CREATED":
      return EventType.MESSAGE_BODY_PARSER_CREATED;
    case 47:
    case "MESSAGE_BODY_PARSER_UPDATED":
      return EventType.MESSAGE_BODY_PARSER_UPDATED;
    case 48:
    case "MESSAGE_BODY_PARSER_DELETED":
      return EventType.MESSAGE_BODY_PARSER_DELETED;
    case 56:
    case "ALARM_TRIGGERED":
      return EventType.ALARM_TRIGGERED;
    case 57:
    case "ALARM_CREATED":
      return EventType.ALARM_CREATED;
    case 58:
    case "ALARM_UPDATED":
      return EventType.ALARM_UPDATED;
    case 59:
    case "ALARM_DELETED":
      return EventType.ALARM_DELETED;
    case 63:
    case "ASSIGNED":
      return EventType.ASSIGNED;
    case 64:
    case "UNASSIGNED":
      return EventType.UNASSIGNED;
    case 18:
    case "TRANSFER":
      return EventType.TRANSFER;
    case 1:
    case "PUBLISHER_CREATED":
      return EventType.PUBLISHER_CREATED;
    case 2:
    case "PUBLISHER_UPDATED":
      return EventType.PUBLISHER_UPDATED;
    case 3:
    case "PUBLISHER_DELETED":
      return EventType.PUBLISHER_DELETED;
    case 11:
    case "PUBLISHER_FAILED":
      return EventType.PUBLISHER_FAILED;
    case 37:
    case "PUBLISHER_AUTO_DISABLED":
      return EventType.PUBLISHER_AUTO_DISABLED;
    case 8:
    case "USER_ACCESS_REQUEST":
      return EventType.USER_ACCESS_REQUEST;
    case 38:
    case "USER_INVITED":
      return EventType.USER_INVITED;
    case 9:
    case "USER_ADDED":
      return EventType.USER_ADDED;
    case 10:
    case "USER_REMOVED":
      return EventType.USER_REMOVED;
    case 54:
    case "USER_VALIDATION_UPDATED":
      return EventType.USER_VALIDATION_UPDATED;
    case 31:
    case "TOKEN_CREATED":
      return EventType.TOKEN_CREATED;
    case 25:
    case "TOKEN_EXPIRY_WARNING":
      return EventType.TOKEN_EXPIRY_WARNING;
    case 26:
    case "TOKEN_EXPIRED":
      return EventType.TOKEN_EXPIRED;
    case 32:
    case "TOKEN_DELETED":
      return EventType.TOKEN_DELETED;
    case 65:
    case "EXPORT_CREATED":
      return EventType.EXPORT_CREATED;
    case 66:
    case "EXPORT_READY":
      return EventType.EXPORT_READY;
    case 67:
    case "EXPORT_FAILED":
      return EventType.EXPORT_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EventType.UNRECOGNIZED;
  }
}

export function eventTypeToNumber(object: EventType): number {
  switch (object) {
    case EventType.DEFAULT:
      return 0;
    case EventType.ORGANIZATION_CREATED:
      return 34;
    case EventType.ORGANIZATION_UPDATED:
      return 12;
    case EventType.ORGANIZATION_DELETED:
      return 35;
    case EventType.ORGANIZATION_EVENT_CONFIGURATION_UPDATED:
      return 43;
    case EventType.ASSET_CREATED:
      return 70;
    case EventType.ASSET_UPDATED:
      return 71;
    case EventType.ASSET_DELETED:
      return 72;
    case EventType.DEVICE_CREATED:
      return 55;
    case EventType.DEVICE_UPDATED:
      return 36;
    case EventType.DEVICE_LOCATION_UPDATED:
      return 4;
    case EventType.DEVICE_INSTALLED:
      return 33;
    case EventType.MESSAGE_RECEIVED:
      return 5;
    case EventType.MESSAGE_BODY_PARSED:
      return 39;
    case EventType.MESSAGE_BODY_RECEIVED:
      return 45;
    case EventType.MESSAGE_CANNOT_BE_PARSED:
      return 15;
    case EventType.MESSAGE_SUMMARY:
      return 42;
    case EventType.MESSAGE_BODY_PARSER_CREATED:
      return 46;
    case EventType.MESSAGE_BODY_PARSER_UPDATED:
      return 47;
    case EventType.MESSAGE_BODY_PARSER_DELETED:
      return 48;
    case EventType.ALARM_TRIGGERED:
      return 56;
    case EventType.ALARM_CREATED:
      return 57;
    case EventType.ALARM_UPDATED:
      return 58;
    case EventType.ALARM_DELETED:
      return 59;
    case EventType.ASSIGNED:
      return 63;
    case EventType.UNASSIGNED:
      return 64;
    case EventType.TRANSFER:
      return 18;
    case EventType.PUBLISHER_CREATED:
      return 1;
    case EventType.PUBLISHER_UPDATED:
      return 2;
    case EventType.PUBLISHER_DELETED:
      return 3;
    case EventType.PUBLISHER_FAILED:
      return 11;
    case EventType.PUBLISHER_AUTO_DISABLED:
      return 37;
    case EventType.USER_ACCESS_REQUEST:
      return 8;
    case EventType.USER_INVITED:
      return 38;
    case EventType.USER_ADDED:
      return 9;
    case EventType.USER_REMOVED:
      return 10;
    case EventType.USER_VALIDATION_UPDATED:
      return 54;
    case EventType.TOKEN_CREATED:
      return 31;
    case EventType.TOKEN_EXPIRY_WARNING:
      return 25;
    case EventType.TOKEN_EXPIRED:
      return 26;
    case EventType.TOKEN_DELETED:
      return 32;
    case EventType.EXPORT_CREATED:
      return 65;
    case EventType.EXPORT_READY:
      return 66;
    case EventType.EXPORT_FAILED:
      return 67;
    case EventType.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Health is an indicator for issues. It is used for publishers to give a quick indication of issues. */
export enum Health {
  OK = "OK",
  WARNING = "WARNING",
  ERROR = "ERROR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function healthFromJSON(object: any): Health {
  switch (object) {
    case 0:
    case "OK":
      return Health.OK;
    case 1:
    case "WARNING":
      return Health.WARNING;
    case 2:
    case "ERROR":
      return Health.ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Health.UNRECOGNIZED;
  }
}

export function healthToNumber(object: Health): number {
  switch (object) {
    case Health.OK:
      return 0;
    case Health.WARNING:
      return 1;
    case Health.ERROR:
      return 2;
    case Health.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Unit of measurement for a numeric value. */
export enum UnitOfMeasurement {
  UNIT_UNKNOWN = "UNIT_UNKNOWN",
  DURATION_MILLISECONDS = "DURATION_MILLISECONDS",
  DURATION_SECONDS = "DURATION_SECONDS",
  DURATION_MINUTES = "DURATION_MINUTES",
  DURATION_HOURS = "DURATION_HOURS",
  DURATION_DAYS = "DURATION_DAYS",
  DURATION_WEEKS = "DURATION_WEEKS",
  FUEL_EFFICIENCY_LITER_PER_100_KILOMETER = "FUEL_EFFICIENCY_LITER_PER_100_KILOMETER",
  FUEL_EFFICIENCY_KILOMETER_PER_LITER = "FUEL_EFFICIENCY_KILOMETER_PER_LITER",
  FUEL_EFFICIENCY_KILOMETER_PER_US_GALLON = "FUEL_EFFICIENCY_KILOMETER_PER_US_GALLON",
  FUEL_EFFICIENCY_KILOMETER_PER_IMPERIAL_GALLON = "FUEL_EFFICIENCY_KILOMETER_PER_IMPERIAL_GALLON",
  FUEL_EFFICIENCY_MILE_PER_US_GALLON = "FUEL_EFFICIENCY_MILE_PER_US_GALLON",
  FUEL_EFFICIENCY_MILE_PER_IMPERIAL_GALLON = "FUEL_EFFICIENCY_MILE_PER_IMPERIAL_GALLON",
  FUEL_EFFICIENCY_MILE_PER_LITER = "FUEL_EFFICIENCY_MILE_PER_LITER",
  DISTANCE_METER = "DISTANCE_METER",
  DISTANCE_MILLIMETER = "DISTANCE_MILLIMETER",
  DISTANCE_CENTIMETER = "DISTANCE_CENTIMETER",
  DISTANCE_KILOMETER = "DISTANCE_KILOMETER",
  DISTANCE_NAUTICAL_MILE = "DISTANCE_NAUTICAL_MILE",
  DISTANCE_MILE = "DISTANCE_MILE",
  DISTANCE_YARD = "DISTANCE_YARD",
  DISTANCE_FOOT = "DISTANCE_FOOT",
  DISTANCE_INCH = "DISTANCE_INCH",
  PERCENT = "PERCENT",
  PRESSURE_BAR = "PRESSURE_BAR",
  PRESSURE_PSI = "PRESSURE_PSI",
  PRESSURE_K_PA = "PRESSURE_K_PA",
  PRESSURE_KILOGRAM_PER_CENTIMETER_SQUARED = "PRESSURE_KILOGRAM_PER_CENTIMETER_SQUARED",
  SPEED_KILOMETERS_PER_HOUR = "SPEED_KILOMETERS_PER_HOUR",
  SPEED_KNOTS = "SPEED_KNOTS",
  SPEED_METERS_PER_SECOND = "SPEED_METERS_PER_SECOND",
  SPEED_MILES_PER_HOUR = "SPEED_MILES_PER_HOUR",
  SPEED_FEET_PER_SECOND = "SPEED_FEET_PER_SECOND",
  TEMPERATURE_KELVIN = "TEMPERATURE_KELVIN",
  TEMPERATURE_DEGREES_CELSIUS = "TEMPERATURE_DEGREES_CELSIUS",
  TEMPERATURE_DEGREES_FAHRENHEIT = "TEMPERATURE_DEGREES_FAHRENHEIT",
  VOLTAGE_MILLIVOLT = "VOLTAGE_MILLIVOLT",
  VOLUME_LITER = "VOLUME_LITER",
  VOLUME_GALLON_US = "VOLUME_GALLON_US",
  VOLUME_GALLON_IMPERIAL = "VOLUME_GALLON_IMPERIAL",
  VOLUME_CUBIC_METER = "VOLUME_CUBIC_METER",
  VOLUME_CUBIC_FOOT = "VOLUME_CUBIC_FOOT",
  MASS_KILOGRAMS = "MASS_KILOGRAMS",
  MASS_POUNDS = "MASS_POUNDS",
  FLOW_BARRELS_PER_DAY = "FLOW_BARRELS_PER_DAY",
  FLOW_CUBIC_METER_PER_HOUR = "FLOW_CUBIC_METER_PER_HOUR",
  FLOW_CUBIC_METER_PER_SECOND = "FLOW_CUBIC_METER_PER_SECOND",
  FLOW_LITER_PER_HOUR = "FLOW_LITER_PER_HOUR",
  FLOW_LITER_PER_SECOND = "FLOW_LITER_PER_SECOND",
  FLOW_CUBIC_FEET_PER_HOUR = "FLOW_CUBIC_FEET_PER_HOUR",
  FLOW_CUBIC_FEET_PER_SECOND = "FLOW_CUBIC_FEET_PER_SECOND",
  REVOLUTIONS_PER_MINUTE = "REVOLUTIONS_PER_MINUTE",
  ITEMS_PER_24_HOURS = "ITEMS_PER_24_HOURS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function unitOfMeasurementFromJSON(object: any): UnitOfMeasurement {
  switch (object) {
    case 0:
    case "UNIT_UNKNOWN":
      return UnitOfMeasurement.UNIT_UNKNOWN;
    case 40:
    case "DURATION_MILLISECONDS":
      return UnitOfMeasurement.DURATION_MILLISECONDS;
    case 1:
    case "DURATION_SECONDS":
      return UnitOfMeasurement.DURATION_SECONDS;
    case 2:
    case "DURATION_MINUTES":
      return UnitOfMeasurement.DURATION_MINUTES;
    case 3:
    case "DURATION_HOURS":
      return UnitOfMeasurement.DURATION_HOURS;
    case 4:
    case "DURATION_DAYS":
      return UnitOfMeasurement.DURATION_DAYS;
    case 41:
    case "DURATION_WEEKS":
      return UnitOfMeasurement.DURATION_WEEKS;
    case 30:
    case "FUEL_EFFICIENCY_LITER_PER_100_KILOMETER":
      return UnitOfMeasurement.FUEL_EFFICIENCY_LITER_PER_100_KILOMETER;
    case 31:
    case "FUEL_EFFICIENCY_KILOMETER_PER_LITER":
      return UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_LITER;
    case 32:
    case "FUEL_EFFICIENCY_KILOMETER_PER_US_GALLON":
      return UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_US_GALLON;
    case 33:
    case "FUEL_EFFICIENCY_KILOMETER_PER_IMPERIAL_GALLON":
      return UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_IMPERIAL_GALLON;
    case 34:
    case "FUEL_EFFICIENCY_MILE_PER_US_GALLON":
      return UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_US_GALLON;
    case 35:
    case "FUEL_EFFICIENCY_MILE_PER_IMPERIAL_GALLON":
      return UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_IMPERIAL_GALLON;
    case 36:
    case "FUEL_EFFICIENCY_MILE_PER_LITER":
      return UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_LITER;
    case 8:
    case "DISTANCE_METER":
      return UnitOfMeasurement.DISTANCE_METER;
    case 9:
    case "DISTANCE_MILLIMETER":
      return UnitOfMeasurement.DISTANCE_MILLIMETER;
    case 10:
    case "DISTANCE_CENTIMETER":
      return UnitOfMeasurement.DISTANCE_CENTIMETER;
    case 11:
    case "DISTANCE_KILOMETER":
      return UnitOfMeasurement.DISTANCE_KILOMETER;
    case 26:
    case "DISTANCE_NAUTICAL_MILE":
      return UnitOfMeasurement.DISTANCE_NAUTICAL_MILE;
    case 21:
    case "DISTANCE_MILE":
      return UnitOfMeasurement.DISTANCE_MILE;
    case 27:
    case "DISTANCE_YARD":
      return UnitOfMeasurement.DISTANCE_YARD;
    case 28:
    case "DISTANCE_FOOT":
      return UnitOfMeasurement.DISTANCE_FOOT;
    case 29:
    case "DISTANCE_INCH":
      return UnitOfMeasurement.DISTANCE_INCH;
    case 16:
    case "PERCENT":
      return UnitOfMeasurement.PERCENT;
    case 12:
    case "PRESSURE_BAR":
      return UnitOfMeasurement.PRESSURE_BAR;
    case 14:
    case "PRESSURE_PSI":
      return UnitOfMeasurement.PRESSURE_PSI;
    case 17:
    case "PRESSURE_K_PA":
      return UnitOfMeasurement.PRESSURE_K_PA;
    case 53:
    case "PRESSURE_KILOGRAM_PER_CENTIMETER_SQUARED":
      return UnitOfMeasurement.PRESSURE_KILOGRAM_PER_CENTIMETER_SQUARED;
    case 18:
    case "SPEED_KILOMETERS_PER_HOUR":
      return UnitOfMeasurement.SPEED_KILOMETERS_PER_HOUR;
    case 19:
    case "SPEED_KNOTS":
      return UnitOfMeasurement.SPEED_KNOTS;
    case 20:
    case "SPEED_METERS_PER_SECOND":
      return UnitOfMeasurement.SPEED_METERS_PER_SECOND;
    case 22:
    case "SPEED_MILES_PER_HOUR":
      return UnitOfMeasurement.SPEED_MILES_PER_HOUR;
    case 47:
    case "SPEED_FEET_PER_SECOND":
      return UnitOfMeasurement.SPEED_FEET_PER_SECOND;
    case 5:
    case "TEMPERATURE_KELVIN":
      return UnitOfMeasurement.TEMPERATURE_KELVIN;
    case 6:
    case "TEMPERATURE_DEGREES_CELSIUS":
      return UnitOfMeasurement.TEMPERATURE_DEGREES_CELSIUS;
    case 7:
    case "TEMPERATURE_DEGREES_FAHRENHEIT":
      return UnitOfMeasurement.TEMPERATURE_DEGREES_FAHRENHEIT;
    case 15:
    case "VOLTAGE_MILLIVOLT":
      return UnitOfMeasurement.VOLTAGE_MILLIVOLT;
    case 23:
    case "VOLUME_LITER":
      return UnitOfMeasurement.VOLUME_LITER;
    case 24:
    case "VOLUME_GALLON_US":
      return UnitOfMeasurement.VOLUME_GALLON_US;
    case 25:
    case "VOLUME_GALLON_IMPERIAL":
      return UnitOfMeasurement.VOLUME_GALLON_IMPERIAL;
    case 42:
    case "VOLUME_CUBIC_METER":
      return UnitOfMeasurement.VOLUME_CUBIC_METER;
    case 43:
    case "VOLUME_CUBIC_FOOT":
      return UnitOfMeasurement.VOLUME_CUBIC_FOOT;
    case 37:
    case "MASS_KILOGRAMS":
      return UnitOfMeasurement.MASS_KILOGRAMS;
    case 38:
    case "MASS_POUNDS":
      return UnitOfMeasurement.MASS_POUNDS;
    case 46:
    case "FLOW_BARRELS_PER_DAY":
      return UnitOfMeasurement.FLOW_BARRELS_PER_DAY;
    case 39:
    case "FLOW_CUBIC_METER_PER_HOUR":
      return UnitOfMeasurement.FLOW_CUBIC_METER_PER_HOUR;
    case 49:
    case "FLOW_CUBIC_METER_PER_SECOND":
      return UnitOfMeasurement.FLOW_CUBIC_METER_PER_SECOND;
    case 51:
    case "FLOW_LITER_PER_HOUR":
      return UnitOfMeasurement.FLOW_LITER_PER_HOUR;
    case 52:
    case "FLOW_LITER_PER_SECOND":
      return UnitOfMeasurement.FLOW_LITER_PER_SECOND;
    case 48:
    case "FLOW_CUBIC_FEET_PER_HOUR":
      return UnitOfMeasurement.FLOW_CUBIC_FEET_PER_HOUR;
    case 50:
    case "FLOW_CUBIC_FEET_PER_SECOND":
      return UnitOfMeasurement.FLOW_CUBIC_FEET_PER_SECOND;
    case 44:
    case "REVOLUTIONS_PER_MINUTE":
      return UnitOfMeasurement.REVOLUTIONS_PER_MINUTE;
    case 45:
    case "ITEMS_PER_24_HOURS":
      return UnitOfMeasurement.ITEMS_PER_24_HOURS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UnitOfMeasurement.UNRECOGNIZED;
  }
}

export function unitOfMeasurementToNumber(object: UnitOfMeasurement): number {
  switch (object) {
    case UnitOfMeasurement.UNIT_UNKNOWN:
      return 0;
    case UnitOfMeasurement.DURATION_MILLISECONDS:
      return 40;
    case UnitOfMeasurement.DURATION_SECONDS:
      return 1;
    case UnitOfMeasurement.DURATION_MINUTES:
      return 2;
    case UnitOfMeasurement.DURATION_HOURS:
      return 3;
    case UnitOfMeasurement.DURATION_DAYS:
      return 4;
    case UnitOfMeasurement.DURATION_WEEKS:
      return 41;
    case UnitOfMeasurement.FUEL_EFFICIENCY_LITER_PER_100_KILOMETER:
      return 30;
    case UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_LITER:
      return 31;
    case UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_US_GALLON:
      return 32;
    case UnitOfMeasurement.FUEL_EFFICIENCY_KILOMETER_PER_IMPERIAL_GALLON:
      return 33;
    case UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_US_GALLON:
      return 34;
    case UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_IMPERIAL_GALLON:
      return 35;
    case UnitOfMeasurement.FUEL_EFFICIENCY_MILE_PER_LITER:
      return 36;
    case UnitOfMeasurement.DISTANCE_METER:
      return 8;
    case UnitOfMeasurement.DISTANCE_MILLIMETER:
      return 9;
    case UnitOfMeasurement.DISTANCE_CENTIMETER:
      return 10;
    case UnitOfMeasurement.DISTANCE_KILOMETER:
      return 11;
    case UnitOfMeasurement.DISTANCE_NAUTICAL_MILE:
      return 26;
    case UnitOfMeasurement.DISTANCE_MILE:
      return 21;
    case UnitOfMeasurement.DISTANCE_YARD:
      return 27;
    case UnitOfMeasurement.DISTANCE_FOOT:
      return 28;
    case UnitOfMeasurement.DISTANCE_INCH:
      return 29;
    case UnitOfMeasurement.PERCENT:
      return 16;
    case UnitOfMeasurement.PRESSURE_BAR:
      return 12;
    case UnitOfMeasurement.PRESSURE_PSI:
      return 14;
    case UnitOfMeasurement.PRESSURE_K_PA:
      return 17;
    case UnitOfMeasurement.PRESSURE_KILOGRAM_PER_CENTIMETER_SQUARED:
      return 53;
    case UnitOfMeasurement.SPEED_KILOMETERS_PER_HOUR:
      return 18;
    case UnitOfMeasurement.SPEED_KNOTS:
      return 19;
    case UnitOfMeasurement.SPEED_METERS_PER_SECOND:
      return 20;
    case UnitOfMeasurement.SPEED_MILES_PER_HOUR:
      return 22;
    case UnitOfMeasurement.SPEED_FEET_PER_SECOND:
      return 47;
    case UnitOfMeasurement.TEMPERATURE_KELVIN:
      return 5;
    case UnitOfMeasurement.TEMPERATURE_DEGREES_CELSIUS:
      return 6;
    case UnitOfMeasurement.TEMPERATURE_DEGREES_FAHRENHEIT:
      return 7;
    case UnitOfMeasurement.VOLTAGE_MILLIVOLT:
      return 15;
    case UnitOfMeasurement.VOLUME_LITER:
      return 23;
    case UnitOfMeasurement.VOLUME_GALLON_US:
      return 24;
    case UnitOfMeasurement.VOLUME_GALLON_IMPERIAL:
      return 25;
    case UnitOfMeasurement.VOLUME_CUBIC_METER:
      return 42;
    case UnitOfMeasurement.VOLUME_CUBIC_FOOT:
      return 43;
    case UnitOfMeasurement.MASS_KILOGRAMS:
      return 37;
    case UnitOfMeasurement.MASS_POUNDS:
      return 38;
    case UnitOfMeasurement.FLOW_BARRELS_PER_DAY:
      return 46;
    case UnitOfMeasurement.FLOW_CUBIC_METER_PER_HOUR:
      return 39;
    case UnitOfMeasurement.FLOW_CUBIC_METER_PER_SECOND:
      return 49;
    case UnitOfMeasurement.FLOW_LITER_PER_HOUR:
      return 51;
    case UnitOfMeasurement.FLOW_LITER_PER_SECOND:
      return 52;
    case UnitOfMeasurement.FLOW_CUBIC_FEET_PER_HOUR:
      return 48;
    case UnitOfMeasurement.FLOW_CUBIC_FEET_PER_SECOND:
      return 50;
    case UnitOfMeasurement.REVOLUTIONS_PER_MINUTE:
      return 44;
    case UnitOfMeasurement.ITEMS_PER_24_HOURS:
      return 45;
    case UnitOfMeasurement.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Update object for an int that can be set to 0.
 *
 * Since 0 is also the default value, we need to distinguish between an omitted value and setting the value to 0,
 * in an update object.
 *
 * To use this to update, set a value and set updated to true
 *
 * DEPRECATED: use alternative optional fields in the relevant places instead.
 *
 * @deprecated
 */
export interface UpdateZeroableInt {
  updated: boolean;
  value: number;
}

/**
 * Update object for a string that can be empty.
 *
 * Since an empty string is also the default value, we need to distinguish between an omitted value and
 * setting the value to an empty string, in an update object.
 *
 * To use this to update, set a value and set updated to true
 *
 * DEPRECATED: use alternative optional fields in the relevant places instead.
 *
 * @deprecated
 */
export interface UpdateClearableString {
  updated: boolean;
  value: string;
}

/**
 * Update object for an optional id.
 *
 * To use this to update, set a value and set updated to true. To clear the id, set updated to true, but set no value.
 *
 * DEPRECATED: use alternative optional fields in the relevant places instead.
 *
 * @deprecated
 */
export interface UpdateOptionalId {
  updated: boolean;
  value: number;
}

/**
 * Update object for an optional Duration.
 *
 * To use this to update, set a value and set updated to true.
 * To clear the duration, set updated to true, but set no value.
 *
 * DEPRECATED: use alternative optional fields in the relevant places instead.
 *
 * @deprecated
 */
export interface UpdateOptionalDuration {
  updated: boolean;
  value: Duration | undefined;
}

/**
 * Update object for a boolean.
 *
 * Since false is the default value, we need to distinguish between an omitted value and setting the value to false,
 * in an update object.
 *
 * To use this to update, set a value and set updated to true
 *
 * DEPRECATED: use alternative optional fields in the relevant places instead.
 *
 * @deprecated
 */
export interface UpdateBoolean {
  updated: boolean;
  value: boolean;
}

/**
 * Period of time between two timestamps. Typically used for filtering.
 *
 * This can be used with textual shortcuts for timestamp, and some additional duration textual shortcuts:
 * - a duration as an offset of now, i.e. "-10h" or "PT-10h": converted to now + offset, so start.textual -10h is
 *   10 hours before the end time (using the ISO 8601 duration format)
 * Examples:
 * - start "-10h" end "now": a time range from 10 hours before the request time, to the request time
 * - start "-10h" end "2022-01-01 20:00": becomes start 2022-01-01 10:00 end 2022-01-01 20:00
 */
export interface TimeRange {
  start: Timestamp | undefined;
  end: Timestamp | undefined;
}

/** Decimal range. */
export interface DoubleRange {
  start: number;
  end: number;
}

/**
 * Timestamp type for convenience.
 *
 * Some clients are better at parsing Google's seconds/nanos based timestamp, while others prefer a text-based format.
 * To accommodate this, this Timestamp type supports both.
 *
 * When used as API output, both the timestamp and textual fields will be set. The textual field has the commonly
 * used ISO 8601 format (i.e. "2018-01-01T13:00:00Z").
 * When used an API input, only one of the fields is needed, there is no need to set both. When both are set, the
 * timestamp field will be used, the textual field will be discarded.
 *
 * In addition, the textual field, when used as input, allows for a number of shortcuts that get converted into
 * timestamps:
 * - "now": converted to the current timestamp at the time of the request
 */
export interface Timestamp {
  timestamp?: Date | undefined;
  timeZone?: string | undefined;
  textual?: string | undefined;
}

/**
 * Date type for convenience.
 *
 * Some clients are better at parsing year, month and day of month as separate fields,
 * while others prefer a text-based format.
 * To accommodate this, this Date type supports both.
 *
 * When used as API output, both the int fields and textual fields will be set.
 * The textual field has the commonly used ISO 8601 local date format (i.e. "2018-01-01").
 * When used an API input, either specify the int fields or the textual field.
 * If both are specified, the textual field will be discarded.
 */
export interface DateMessage {
  year?: number | undefined;
  month?: number | undefined;
  day?: number | undefined;
  textual?: string | undefined;
}

export interface Duration {
  duration?: Duration1 | undefined;
  textual?: string | undefined;
}

/**
 * Geographic latitude and longitude coordinates specified in decimal degrees.
 * For more information, see the WGS-84 coordinate system, which is used for most GPS systems.
 */
export interface Location {
  /** Decimal degrees north. */
  latitude: number;
  /** Decimal degrees east. */
  longitude: number;
  /** Text representation. Can be used as an alternative input in a request, filled in by the API in responses. */
  textual?: string | undefined;
}

/**
 * Rectangular area between two locations, normalized to bottom-left and top-right points.
 *
 * Center point is added for convenience; it's simple the point directly between the two corner points.
 * When sending an Area to the api, the center location is ignored.
 */
export interface Area {
  center: Location | undefined;
  bottomLeft: Location | undefined;
  topRight:
    | Location
    | undefined;
  /** Text representation. Can be used as an alternative input in a request, filled in by the API in responses. */
  textual?: string | undefined;
}

/**
 * Polygon shape defined by a list of locations, which draw a shape on the map.
 * The last point is connected to the first to close the shape.
 *
 * For example, the outline of a city would be defined using a Shape,
 * while a rectangular region is easier to define using Area.
 */
export interface Shape {
  path: Location[];
  /** Text representation. Can be used as an alternative input in a request, filled in by the API in responses. */
  textual?: string | undefined;
}

/**
 * Selection object for map data.
 *
 * Also, filter the map data by level and area restriction, to only display a small area at a detailed map level,
 * for example
 */
export interface LocationSelection {
  /** Rectangular areas, each defined by two locations, normalized to bottom-left and top-right points. */
  areas: Area[];
  /** Polygon shapes, each defined by a list of locations, which draw a shape on the map. */
  shapes: Shape[];
}

/**
 * Some clients may prefer direct binary data, while other prefer a hexadecimal string,
 * both for input and output. To support both methods, this object is used to represent binary data.
 *
 * When you receive this from the api, both fields are set. When sending it to the api, only one field is required.
 */
export interface BytesOrHex {
  bytes?: Uint8Array | undefined;
  hex?: string | undefined;
}

/** @deprecated */
export interface BytesOrHex_Update {
  updated: boolean;
  value: BytesOrHex | undefined;
}

/**
 * Pagination is normalized across the api. Provide a pagination object to get a specific page or offset,
 * or limit your data.
 *
 * Calls that have a pagination option automatically return a Pagination.Result, which contains
 * either the specified pagination options or the defaults, as well as total counts. It also contains Pagination
 * objects that can be used for the previous and next page.
 *
 * This effectively means that an api user would never need to create their own pagination object; as long as they
 * start at the first page and continue to the next, they can use the provided Pagination object.
 */
export interface Pagination {
  size?: number | undefined;
  page?: number | undefined;
}

export interface Pagination_Result {
  size: number;
  page: number;
  total: number;
  totalPages: number;
  previous: Pagination | undefined;
  next:
    | Pagination
    | undefined;
  /**
   * Indicates that the total is an approximation, and not an exact value.
   * This can be set for data that changes often, or is generally only fetched in an infinite scrolling manner.
   * For example, unbundled events are likely to return an approximated total, but not guaranteed to do so.
   */
  approximatedTotal: boolean;
}

/**
 * Filters used in many api calls to filter the data sources, results, etc.
 *
 * "Include" fields filter out anything not in the include set.
 * When not set, all items will be returned (except excluded items)
 *
 * "Exclude" fields filter out anything in the exclude set.
 * When combined with include, exclude takes precedence when determining whether an item is filtered
 */
export interface Filter {
}

/**
 * Specify which organizations to get data from. By default, data is only retrieved for the current organization,
 * but using ChildOrganizations we can specify to include a number of, or all, sub-organizations.
 *
 * Note: ChildOrganization differs from other filters in that it defaults to not allowing anything, where the
 * other filters default to allowing everything
 */
export interface Filter_ChildOrganizations {
  includeAll: boolean;
  include: string[];
  exclude: string[];
}

/**
 * Update object to update a Filter.ChildOrganizations field.
 *
 * @deprecated
 */
export interface Filter_ChildOrganizations_Update {
  updated: boolean;
  value: Filter_ChildOrganizations | undefined;
}

export interface Filter_Organizations {
  include: string[];
  exclude: string[];
}

export interface Filter_Events {
  include: EventType[];
  exclude: EventType[];
}

/**
 * Update object to update a Filter.Events field.
 *
 * @deprecated
 */
export interface Filter_Events_Update {
  updated: boolean;
  value: Filter_Events | undefined;
}

export interface Filter_Modems {
  /** Include all modems with these modem numbers (HEX) */
  include: string[];
  /**
   * Exclude all modems with these modem numbers (HEX).
   * Exclude takes precedence over include.
   */
  exclude: string[];
}

/**
 * Update object to update a Filter.Modems field.
 *
 * @deprecated
 */
export interface Filter_Modems_Update {
  updated: boolean;
  value: Filter_Modems | undefined;
}

export interface Filter_ModemIdentifiers {
  include: string[];
  exclude: string[];
}

export interface Filter_DeviceTypes {
  include: string[];
  exclude: string[];
}

export interface Filter_SensorBrands {
  include: string[];
  exclude: string[];
}

export interface Filter_Tags {
  include: number[];
  exclude: number[];
}

/**
 * Update object to update a Filter.Tags field.
 *
 * @deprecated
 */
export interface Filter_Tags_Update {
  updated: boolean;
  value: Filter_Tags | undefined;
}

export interface Filter_Webhooks {
  include: number[];
  exclude: number[];
  onlyActive: boolean;
}

export interface Filter_Publishers {
  include: number[];
  exclude: number[];
  onlyActive: boolean;
}

export interface Filter_Users {
  include: string[];
  exclude: string[];
}

export interface Filter_Roles {
  include: string[];
  exclude: string[];
}

export interface Filter_OrganizationPermissions {
  includeAll: boolean;
  include: OrganizationPermission[];
  exclude: OrganizationPermission[];
}

export interface Filter_UserPermissions {
  includeAll: boolean;
  include: UserPermission[];
  exclude: UserPermission[];
}

export interface Filter_SupportPermissions {
  include: SupportPermission[];
  exclude: SupportPermission[];
}

export interface Filter_FieldEnumValues {
  field: string;
  include: string[];
  exclude: string[];
}

export interface Filter_HealthLevels {
  include: string[];
  exclude: string[];
}

/** Filter result on specific properties encoded in map-value pairs. */
export interface Filter_Properties {
  selection?:
    | { $case: "properties"; properties: MapFilter }
    | { $case: "includeOnlyEmpty"; includeOnlyEmpty: boolean }
    | undefined;
}

/**
 * Some properties are stored as a name-value pair (e.g. bluetooth: 4.0, bluetooth: BLE).
 * This filter allows selecting a range of values for a specific name.
 * One could imagine wanting to include "all devices with bluetooth 4.0 or 4.1".
 *
 * To select for multiple versions of a property,
 * add the name of the property as a map-key and add a repeated list of versions as the map-value.
 *
 * For example:
 * - include { 'bluetooth' -> [ ] }
 *     returns all items that have any version of bluetooth,
 * - include { 'bluetooth' -> [ '4.0', '5.0' ] }
 *     will only return items that have bluetooth version 4.0 _or_ 5.0 (inclusive or),
 * - include { 'bluetooth' -> [ '' ] }
 *     would only select bluetooth peripherals that don't have any version set,
 * - include { 'bluetooth' -> [ ], 'LoRaWAN' -> [ ] }
 *     will only select items that have both bluetooth (any version) _and_ LoRaWAN (any version),
 * - include { 'bluetooth' -> [ ] }, exclude { 'bluetooth' -> [ ] }
 *     will return an empty list since exclude will take precedence, and
 * - include { 'bluetooth' -> [ ] }, exclude { 'bluetooth' -> [ '3.0' ] }
 *     returns only items that have bluetooth, but not version 3.0.
 */
export interface MapFilter {
  /** Filter to only include items with all of the given set of properties. */
  includeAnd: { [key: string]: MapFilter_OneOfValues };
  /** Filter to exclude items with any of the given set of properties. */
  exclude: { [key: string]: MapFilter_OneOfValues };
}

/**
 * Technical solution to make map<k, v> into a map<k, repeated v>,
 * which is not possible in protobuf without trickery.
 */
export interface MapFilter_OneOfValues {
  value: string[];
}

export interface MapFilter_IncludeAndEntry {
  key: string;
  value: MapFilter_OneOfValues | undefined;
}

export interface MapFilter_ExcludeEntry {
  key: string;
  value: MapFilter_OneOfValues | undefined;
}

/**
 * A NamedFile contains bytes with its mime-type and name.
 * It can represent any file of any type.
 *
 * Note that depending on where in the API this is used,
 * the server might put restrictions on file size, media-type or name length.
 *
 * The file name should be interpreted as-is.
 * No hierarchical information is stored in the name, nor should you look at the "extension" to know its media-type.
 * It might not even have a file extension.
 * The file name may contain characters that cannot be a valid file name on certain systems.
 *
 * Specific API calls may pur restrictions on the name or size of the file.
 *
 * When showing this as an image in a browser, one can make use of a `data` URI.
 * The client must convert the bytes to base64 and can then construct a data URI like this
 *
 *     data:<media-type>;base64,<base64-encoded-bytes>
 *
 * Other type clients should be able to sort-of-directly set the data bytes as the source for an image.
 */
export interface NamedFile {
  /** The binary payload that represents the file */
  data:
    | BytesOrHex
    | undefined;
  /** The media-type of the file, as defined by RFC 6838 or its extensions */
  mediaType: string;
  /** A semantic name for this file. */
  name: string;
}

/**
 * An avatar is represented either by a (publicly) fetchable URL that serves an image,
 * xor a binary payload that knows its name and mime-type.
 *
 * If it is a url, it must be obtainable without credentials, though this is not validated by the API.
 * Because the content behind URL's can change or become unavailable over time,
 * the client should make sure it properly caches the data fetched from the URL.
 * ("Properly" means [among other things] respecting the response headers for this resource)
 */
export interface Avatar {
  urlOrImage?: { $case: "url"; url: string } | { $case: "image"; image: NamedFile } | undefined;
}

function createBaseUpdateZeroableInt(): UpdateZeroableInt {
  return { updated: false, value: 0 };
}

export const UpdateZeroableInt = {
  encode(message: UpdateZeroableInt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateZeroableInt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateZeroableInt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateZeroableInt>): UpdateZeroableInt {
    return UpdateZeroableInt.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateZeroableInt>): UpdateZeroableInt {
    const message = createBaseUpdateZeroableInt();
    message.updated = object.updated ?? false;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseUpdateClearableString(): UpdateClearableString {
  return { updated: false, value: "" };
}

export const UpdateClearableString = {
  encode(message: UpdateClearableString, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateClearableString {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateClearableString();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateClearableString>): UpdateClearableString {
    return UpdateClearableString.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateClearableString>): UpdateClearableString {
    const message = createBaseUpdateClearableString();
    message.updated = object.updated ?? false;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateOptionalId(): UpdateOptionalId {
  return { updated: false, value: 0 };
}

export const UpdateOptionalId = {
  encode(message: UpdateOptionalId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOptionalId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOptionalId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateOptionalId>): UpdateOptionalId {
    return UpdateOptionalId.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateOptionalId>): UpdateOptionalId {
    const message = createBaseUpdateOptionalId();
    message.updated = object.updated ?? false;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseUpdateOptionalDuration(): UpdateOptionalDuration {
  return { updated: false, value: undefined };
}

export const UpdateOptionalDuration = {
  encode(message: UpdateOptionalDuration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Duration.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOptionalDuration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOptionalDuration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateOptionalDuration>): UpdateOptionalDuration {
    return UpdateOptionalDuration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateOptionalDuration>): UpdateOptionalDuration {
    const message = createBaseUpdateOptionalDuration();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Duration.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateBoolean(): UpdateBoolean {
  return { updated: false, value: false };
}

export const UpdateBoolean = {
  encode(message: UpdateBoolean, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== false) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBoolean {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBoolean();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateBoolean>): UpdateBoolean {
    return UpdateBoolean.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateBoolean>): UpdateBoolean {
    const message = createBaseUpdateBoolean();
    message.updated = object.updated ?? false;
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseTimeRange(): TimeRange {
  return { start: undefined, end: undefined };
}

export const TimeRange = {
  encode(message: TimeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.start !== undefined) {
      Timestamp.encode(message.start, writer.uint32(10).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Timestamp.encode(message.end, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.start = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.end = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TimeRange>): TimeRange {
    return TimeRange.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TimeRange>): TimeRange {
    const message = createBaseTimeRange();
    message.start = (object.start !== undefined && object.start !== null)
      ? Timestamp.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? Timestamp.fromPartial(object.end) : undefined;
    return message;
  },
};

function createBaseDoubleRange(): DoubleRange {
  return { start: 0, end: 0 };
}

export const DoubleRange = {
  encode(message: DoubleRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.start !== 0) {
      writer.uint32(9).double(message.start);
    }
    if (message.end !== 0) {
      writer.uint32(17).double(message.end);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoubleRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoubleRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.start = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.end = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DoubleRange>): DoubleRange {
    return DoubleRange.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DoubleRange>): DoubleRange {
    const message = createBaseDoubleRange();
    message.start = object.start ?? 0;
    message.end = object.end ?? 0;
    return message;
  },
};

function createBaseTimestamp(): Timestamp {
  return { timestamp: undefined, timeZone: undefined, textual: undefined };
}

export const Timestamp = {
  encode(message: Timestamp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp2.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.timeZone !== undefined) {
      writer.uint32(18).string(message.timeZone);
    }
    if (message.textual !== undefined) {
      writer.uint32(34).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Timestamp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimestamp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp2.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Timestamp>): Timestamp {
    return Timestamp.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Timestamp>): Timestamp {
    const message = createBaseTimestamp();
    message.timestamp = object.timestamp ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseDateMessage(): DateMessage {
  return { year: undefined, month: undefined, day: undefined, textual: undefined };
}

export const DateMessage = {
  encode(message: DateMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.year !== undefined) {
      writer.uint32(8).uint32(message.year);
    }
    if (message.month !== undefined) {
      writer.uint32(16).uint32(message.month);
    }
    if (message.day !== undefined) {
      writer.uint32(24).uint32(message.day);
    }
    if (message.textual !== undefined) {
      writer.uint32(34).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DateMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDateMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.year = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.month = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.day = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DateMessage>): DateMessage {
    return DateMessage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DateMessage>): DateMessage {
    const message = createBaseDateMessage();
    message.year = object.year ?? undefined;
    message.month = object.month ?? undefined;
    message.day = object.day ?? undefined;
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseDuration(): Duration {
  return { duration: undefined, textual: undefined };
}

export const Duration = {
  encode(message: Duration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.duration !== undefined) {
      Duration1.encode(message.duration, writer.uint32(10).fork()).ldelim();
    }
    if (message.textual !== undefined) {
      writer.uint32(18).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Duration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDuration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.duration = Duration1.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Duration>): Duration {
    return Duration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Duration>): Duration {
    const message = createBaseDuration();
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration1.fromPartial(object.duration)
      : undefined;
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseLocation(): Location {
  return { latitude: 0, longitude: 0, textual: undefined };
}

export const Location = {
  encode(message: Location, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude);
    }
    if (message.textual !== undefined) {
      writer.uint32(42).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Location {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Location>): Location {
    return Location.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Location>): Location {
    const message = createBaseLocation();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseArea(): Area {
  return { center: undefined, bottomLeft: undefined, topRight: undefined, textual: undefined };
}

export const Area = {
  encode(message: Area, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.center !== undefined) {
      Location.encode(message.center, writer.uint32(10).fork()).ldelim();
    }
    if (message.bottomLeft !== undefined) {
      Location.encode(message.bottomLeft, writer.uint32(18).fork()).ldelim();
    }
    if (message.topRight !== undefined) {
      Location.encode(message.topRight, writer.uint32(34).fork()).ldelim();
    }
    if (message.textual !== undefined) {
      writer.uint32(42).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Area {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArea();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.center = Location.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bottomLeft = Location.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.topRight = Location.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Area>): Area {
    return Area.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Area>): Area {
    const message = createBaseArea();
    message.center = (object.center !== undefined && object.center !== null)
      ? Location.fromPartial(object.center)
      : undefined;
    message.bottomLeft = (object.bottomLeft !== undefined && object.bottomLeft !== null)
      ? Location.fromPartial(object.bottomLeft)
      : undefined;
    message.topRight = (object.topRight !== undefined && object.topRight !== null)
      ? Location.fromPartial(object.topRight)
      : undefined;
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseShape(): Shape {
  return { path: [], textual: undefined };
}

export const Shape = {
  encode(message: Shape, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.path) {
      Location.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.textual !== undefined) {
      writer.uint32(42).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Shape {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShape();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path.push(Location.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Shape>): Shape {
    return Shape.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Shape>): Shape {
    const message = createBaseShape();
    message.path = object.path?.map((e) => Location.fromPartial(e)) || [];
    message.textual = object.textual ?? undefined;
    return message;
  },
};

function createBaseLocationSelection(): LocationSelection {
  return { areas: [], shapes: [] };
}

export const LocationSelection = {
  encode(message: LocationSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.areas) {
      Area.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.shapes) {
      Shape.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.areas.push(Area.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.shapes.push(Shape.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LocationSelection>): LocationSelection {
    return LocationSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LocationSelection>): LocationSelection {
    const message = createBaseLocationSelection();
    message.areas = object.areas?.map((e) => Area.fromPartial(e)) || [];
    message.shapes = object.shapes?.map((e) => Shape.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBytesOrHex(): BytesOrHex {
  return { bytes: undefined, hex: undefined };
}

export const BytesOrHex = {
  encode(message: BytesOrHex, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bytes !== undefined) {
      writer.uint32(10).bytes(message.bytes);
    }
    if (message.hex !== undefined) {
      writer.uint32(18).string(message.hex);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BytesOrHex {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBytesOrHex();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bytes = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hex = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<BytesOrHex>): BytesOrHex {
    return BytesOrHex.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BytesOrHex>): BytesOrHex {
    const message = createBaseBytesOrHex();
    message.bytes = object.bytes ?? undefined;
    message.hex = object.hex ?? undefined;
    return message;
  },
};

function createBaseBytesOrHex_Update(): BytesOrHex_Update {
  return { updated: false, value: undefined };
}

export const BytesOrHex_Update = {
  encode(message: BytesOrHex_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      BytesOrHex.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BytesOrHex_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBytesOrHex_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = BytesOrHex.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<BytesOrHex_Update>): BytesOrHex_Update {
    return BytesOrHex_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BytesOrHex_Update>): BytesOrHex_Update {
    const message = createBaseBytesOrHex_Update();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? BytesOrHex.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBasePagination(): Pagination {
  return { size: undefined, page: undefined };
}

export const Pagination = {
  encode(message: Pagination, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.size !== undefined) {
      writer.uint32(8).int32(message.size);
    }
    if (message.page !== undefined) {
      writer.uint32(16).int32(message.page);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pagination {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.page = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Pagination>): Pagination {
    return Pagination.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Pagination>): Pagination {
    const message = createBasePagination();
    message.size = object.size ?? undefined;
    message.page = object.page ?? undefined;
    return message;
  },
};

function createBasePagination_Result(): Pagination_Result {
  return { size: 0, page: 0, total: 0, totalPages: 0, previous: undefined, next: undefined, approximatedTotal: false };
}

export const Pagination_Result = {
  encode(message: Pagination_Result, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.size !== 0) {
      writer.uint32(8).int32(message.size);
    }
    if (message.page !== 0) {
      writer.uint32(16).int32(message.page);
    }
    if (message.total !== 0) {
      writer.uint32(24).int32(message.total);
    }
    if (message.totalPages !== 0) {
      writer.uint32(32).int32(message.totalPages);
    }
    if (message.previous !== undefined) {
      Pagination.encode(message.previous, writer.uint32(50).fork()).ldelim();
    }
    if (message.next !== undefined) {
      Pagination.encode(message.next, writer.uint32(58).fork()).ldelim();
    }
    if (message.approximatedTotal !== false) {
      writer.uint32(64).bool(message.approximatedTotal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pagination_Result {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagination_Result();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.size = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.page = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalPages = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.previous = Pagination.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.next = Pagination.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.approximatedTotal = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Pagination_Result>): Pagination_Result {
    return Pagination_Result.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Pagination_Result>): Pagination_Result {
    const message = createBasePagination_Result();
    message.size = object.size ?? 0;
    message.page = object.page ?? 0;
    message.total = object.total ?? 0;
    message.totalPages = object.totalPages ?? 0;
    message.previous = (object.previous !== undefined && object.previous !== null)
      ? Pagination.fromPartial(object.previous)
      : undefined;
    message.next = (object.next !== undefined && object.next !== null)
      ? Pagination.fromPartial(object.next)
      : undefined;
    message.approximatedTotal = object.approximatedTotal ?? false;
    return message;
  },
};

function createBaseFilter(): Filter {
  return {};
}

export const Filter = {
  encode(_: Filter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter>): Filter {
    return Filter.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Filter>): Filter {
    const message = createBaseFilter();
    return message;
  },
};

function createBaseFilter_ChildOrganizations(): Filter_ChildOrganizations {
  return { includeAll: false, include: [], exclude: [] };
}

export const Filter_ChildOrganizations = {
  encode(message: Filter_ChildOrganizations, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.includeAll !== false) {
      writer.uint32(8).bool(message.includeAll);
    }
    for (const v of message.include) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_ChildOrganizations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_ChildOrganizations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeAll = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_ChildOrganizations>): Filter_ChildOrganizations {
    return Filter_ChildOrganizations.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_ChildOrganizations>): Filter_ChildOrganizations {
    const message = createBaseFilter_ChildOrganizations();
    message.includeAll = object.includeAll ?? false;
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_ChildOrganizations_Update(): Filter_ChildOrganizations_Update {
  return { updated: false, value: undefined };
}

export const Filter_ChildOrganizations_Update = {
  encode(message: Filter_ChildOrganizations_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_ChildOrganizations.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_ChildOrganizations_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_ChildOrganizations_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_ChildOrganizations_Update>): Filter_ChildOrganizations_Update {
    return Filter_ChildOrganizations_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_ChildOrganizations_Update>): Filter_ChildOrganizations_Update {
    const message = createBaseFilter_ChildOrganizations_Update();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_ChildOrganizations.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFilter_Organizations(): Filter_Organizations {
  return { include: [], exclude: [] };
}

export const Filter_Organizations = {
  encode(message: Filter_Organizations, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Organizations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Organizations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Organizations>): Filter_Organizations {
    return Filter_Organizations.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Organizations>): Filter_Organizations {
    const message = createBaseFilter_Organizations();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Events(): Filter_Events {
  return { include: [], exclude: [] };
}

export const Filter_Events = {
  encode(message: Filter_Events, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int32(eventTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int32(eventTypeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Events {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Events();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(eventTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(eventTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(eventTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(eventTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Events>): Filter_Events {
    return Filter_Events.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Events>): Filter_Events {
    const message = createBaseFilter_Events();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Events_Update(): Filter_Events_Update {
  return { updated: false, value: undefined };
}

export const Filter_Events_Update = {
  encode(message: Filter_Events_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Events.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Events_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Events_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Events.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Events_Update>): Filter_Events_Update {
    return Filter_Events_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Events_Update>): Filter_Events_Update {
    const message = createBaseFilter_Events_Update();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Events.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFilter_Modems(): Filter_Modems {
  return { include: [], exclude: [] };
}

export const Filter_Modems = {
  encode(message: Filter_Modems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Modems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Modems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Modems>): Filter_Modems {
    return Filter_Modems.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Modems>): Filter_Modems {
    const message = createBaseFilter_Modems();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Modems_Update(): Filter_Modems_Update {
  return { updated: false, value: undefined };
}

export const Filter_Modems_Update = {
  encode(message: Filter_Modems_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Modems.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Modems_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Modems_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Modems.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Modems_Update>): Filter_Modems_Update {
    return Filter_Modems_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Modems_Update>): Filter_Modems_Update {
    const message = createBaseFilter_Modems_Update();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Modems.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFilter_ModemIdentifiers(): Filter_ModemIdentifiers {
  return { include: [], exclude: [] };
}

export const Filter_ModemIdentifiers = {
  encode(message: Filter_ModemIdentifiers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_ModemIdentifiers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_ModemIdentifiers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_ModemIdentifiers>): Filter_ModemIdentifiers {
    return Filter_ModemIdentifiers.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_ModemIdentifiers>): Filter_ModemIdentifiers {
    const message = createBaseFilter_ModemIdentifiers();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_DeviceTypes(): Filter_DeviceTypes {
  return { include: [], exclude: [] };
}

export const Filter_DeviceTypes = {
  encode(message: Filter_DeviceTypes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_DeviceTypes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_DeviceTypes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_DeviceTypes>): Filter_DeviceTypes {
    return Filter_DeviceTypes.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_DeviceTypes>): Filter_DeviceTypes {
    const message = createBaseFilter_DeviceTypes();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_SensorBrands(): Filter_SensorBrands {
  return { include: [], exclude: [] };
}

export const Filter_SensorBrands = {
  encode(message: Filter_SensorBrands, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_SensorBrands {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_SensorBrands();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_SensorBrands>): Filter_SensorBrands {
    return Filter_SensorBrands.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_SensorBrands>): Filter_SensorBrands {
    const message = createBaseFilter_SensorBrands();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Tags(): Filter_Tags {
  return { include: [], exclude: [] };
}

export const Filter_Tags = {
  encode(message: Filter_Tags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Tags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Tags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Tags>): Filter_Tags {
    return Filter_Tags.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Tags>): Filter_Tags {
    const message = createBaseFilter_Tags();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Tags_Update(): Filter_Tags_Update {
  return { updated: false, value: undefined };
}

export const Filter_Tags_Update = {
  encode(message: Filter_Tags_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== false) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Tags.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Tags_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Tags_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Tags.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Tags_Update>): Filter_Tags_Update {
    return Filter_Tags_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Tags_Update>): Filter_Tags_Update {
    const message = createBaseFilter_Tags_Update();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Tags.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFilter_Webhooks(): Filter_Webhooks {
  return { include: [], exclude: [], onlyActive: false };
}

export const Filter_Webhooks = {
  encode(message: Filter_Webhooks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.onlyActive !== false) {
      writer.uint32(24).bool(message.onlyActive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Webhooks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Webhooks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.onlyActive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Webhooks>): Filter_Webhooks {
    return Filter_Webhooks.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Webhooks>): Filter_Webhooks {
    const message = createBaseFilter_Webhooks();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    message.onlyActive = object.onlyActive ?? false;
    return message;
  },
};

function createBaseFilter_Publishers(): Filter_Publishers {
  return { include: [], exclude: [], onlyActive: false };
}

export const Filter_Publishers = {
  encode(message: Filter_Publishers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.onlyActive !== false) {
      writer.uint32(24).bool(message.onlyActive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Publishers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Publishers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.onlyActive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Publishers>): Filter_Publishers {
    return Filter_Publishers.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Publishers>): Filter_Publishers {
    const message = createBaseFilter_Publishers();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    message.onlyActive = object.onlyActive ?? false;
    return message;
  },
};

function createBaseFilter_Users(): Filter_Users {
  return { include: [], exclude: [] };
}

export const Filter_Users = {
  encode(message: Filter_Users, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Users {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Users();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Users>): Filter_Users {
    return Filter_Users.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Users>): Filter_Users {
    const message = createBaseFilter_Users();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Roles(): Filter_Roles {
  return { include: [], exclude: [] };
}

export const Filter_Roles = {
  encode(message: Filter_Roles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Roles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Roles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Roles>): Filter_Roles {
    return Filter_Roles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Roles>): Filter_Roles {
    const message = createBaseFilter_Roles();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_OrganizationPermissions(): Filter_OrganizationPermissions {
  return { includeAll: false, include: [], exclude: [] };
}

export const Filter_OrganizationPermissions = {
  encode(message: Filter_OrganizationPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.includeAll !== false) {
      writer.uint32(8).bool(message.includeAll);
    }
    writer.uint32(18).fork();
    for (const v of message.include) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.exclude) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_OrganizationPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_OrganizationPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeAll = reader.bool();
          continue;
        case 2:
          if (tag === 16) {
            message.include.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.exclude.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_OrganizationPermissions>): Filter_OrganizationPermissions {
    return Filter_OrganizationPermissions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_OrganizationPermissions>): Filter_OrganizationPermissions {
    const message = createBaseFilter_OrganizationPermissions();
    message.includeAll = object.includeAll ?? false;
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_UserPermissions(): Filter_UserPermissions {
  return { includeAll: false, include: [], exclude: [] };
}

export const Filter_UserPermissions = {
  encode(message: Filter_UserPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.includeAll !== false) {
      writer.uint32(8).bool(message.includeAll);
    }
    writer.uint32(18).fork();
    for (const v of message.include) {
      writer.int32(userPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.exclude) {
      writer.int32(userPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_UserPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_UserPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.includeAll = reader.bool();
          continue;
        case 2:
          if (tag === 16) {
            message.include.push(userPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(userPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.exclude.push(userPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(userPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_UserPermissions>): Filter_UserPermissions {
    return Filter_UserPermissions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_UserPermissions>): Filter_UserPermissions {
    const message = createBaseFilter_UserPermissions();
    message.includeAll = object.includeAll ?? false;
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_SupportPermissions(): Filter_SupportPermissions {
  return { include: [], exclude: [] };
}

export const Filter_SupportPermissions = {
  encode(message: Filter_SupportPermissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int32(supportPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int32(supportPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_SupportPermissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_SupportPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(supportPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(supportPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(supportPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(supportPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_SupportPermissions>): Filter_SupportPermissions {
    return Filter_SupportPermissions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_SupportPermissions>): Filter_SupportPermissions {
    const message = createBaseFilter_SupportPermissions();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_FieldEnumValues(): Filter_FieldEnumValues {
  return { field: "", include: [], exclude: [] };
}

export const Filter_FieldEnumValues = {
  encode(message: Filter_FieldEnumValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.field !== "") {
      writer.uint32(10).string(message.field);
    }
    for (const v of message.include) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_FieldEnumValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_FieldEnumValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.field = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_FieldEnumValues>): Filter_FieldEnumValues {
    return Filter_FieldEnumValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_FieldEnumValues>): Filter_FieldEnumValues {
    const message = createBaseFilter_FieldEnumValues();
    message.field = object.field ?? "";
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_HealthLevels(): Filter_HealthLevels {
  return { include: [], exclude: [] };
}

export const Filter_HealthLevels = {
  encode(message: Filter_HealthLevels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.include) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.exclude) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_HealthLevels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_HealthLevels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.include.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exclude.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_HealthLevels>): Filter_HealthLevels {
    return Filter_HealthLevels.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_HealthLevels>): Filter_HealthLevels {
    const message = createBaseFilter_HealthLevels();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseFilter_Properties(): Filter_Properties {
  return { selection: undefined };
}

export const Filter_Properties = {
  encode(message: Filter_Properties, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.selection?.$case) {
      case "properties":
        MapFilter.encode(message.selection.properties, writer.uint32(10).fork()).ldelim();
        break;
      case "includeOnlyEmpty":
        writer.uint32(16).bool(message.selection.includeOnlyEmpty);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter_Properties {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter_Properties();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selection = { $case: "properties", properties: MapFilter.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.selection = { $case: "includeOnlyEmpty", includeOnlyEmpty: reader.bool() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Filter_Properties>): Filter_Properties {
    return Filter_Properties.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Filter_Properties>): Filter_Properties {
    const message = createBaseFilter_Properties();
    if (
      object.selection?.$case === "properties" &&
      object.selection?.properties !== undefined &&
      object.selection?.properties !== null
    ) {
      message.selection = { $case: "properties", properties: MapFilter.fromPartial(object.selection.properties) };
    }
    if (
      object.selection?.$case === "includeOnlyEmpty" &&
      object.selection?.includeOnlyEmpty !== undefined &&
      object.selection?.includeOnlyEmpty !== null
    ) {
      message.selection = { $case: "includeOnlyEmpty", includeOnlyEmpty: object.selection.includeOnlyEmpty };
    }
    return message;
  },
};

function createBaseMapFilter(): MapFilter {
  return { includeAnd: {}, exclude: {} };
}

export const MapFilter = {
  encode(message: MapFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.includeAnd).forEach(([key, value]) => {
      MapFilter_IncludeAndEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    Object.entries(message.exclude).forEach(([key, value]) => {
      MapFilter_ExcludeEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = MapFilter_IncludeAndEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.includeAnd[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = MapFilter_ExcludeEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.exclude[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapFilter>): MapFilter {
    return MapFilter.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapFilter>): MapFilter {
    const message = createBaseMapFilter();
    message.includeAnd = Object.entries(object.includeAnd ?? {}).reduce<{ [key: string]: MapFilter_OneOfValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = MapFilter_OneOfValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.exclude = Object.entries(object.exclude ?? {}).reduce<{ [key: string]: MapFilter_OneOfValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = MapFilter_OneOfValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseMapFilter_OneOfValues(): MapFilter_OneOfValues {
  return { value: [] };
}

export const MapFilter_OneOfValues = {
  encode(message: MapFilter_OneOfValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.value) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapFilter_OneOfValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapFilter_OneOfValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapFilter_OneOfValues>): MapFilter_OneOfValues {
    return MapFilter_OneOfValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapFilter_OneOfValues>): MapFilter_OneOfValues {
    const message = createBaseMapFilter_OneOfValues();
    message.value = object.value?.map((e) => e) || [];
    return message;
  },
};

function createBaseMapFilter_IncludeAndEntry(): MapFilter_IncludeAndEntry {
  return { key: "", value: undefined };
}

export const MapFilter_IncludeAndEntry = {
  encode(message: MapFilter_IncludeAndEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      MapFilter_OneOfValues.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapFilter_IncludeAndEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapFilter_IncludeAndEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = MapFilter_OneOfValues.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapFilter_IncludeAndEntry>): MapFilter_IncludeAndEntry {
    return MapFilter_IncludeAndEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapFilter_IncludeAndEntry>): MapFilter_IncludeAndEntry {
    const message = createBaseMapFilter_IncludeAndEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? MapFilter_OneOfValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseMapFilter_ExcludeEntry(): MapFilter_ExcludeEntry {
  return { key: "", value: undefined };
}

export const MapFilter_ExcludeEntry = {
  encode(message: MapFilter_ExcludeEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      MapFilter_OneOfValues.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapFilter_ExcludeEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapFilter_ExcludeEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = MapFilter_OneOfValues.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapFilter_ExcludeEntry>): MapFilter_ExcludeEntry {
    return MapFilter_ExcludeEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapFilter_ExcludeEntry>): MapFilter_ExcludeEntry {
    const message = createBaseMapFilter_ExcludeEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? MapFilter_OneOfValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseNamedFile(): NamedFile {
  return { data: undefined, mediaType: "", name: "" };
}

export const NamedFile = {
  encode(message: NamedFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      BytesOrHex.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    if (message.mediaType !== "") {
      writer.uint32(18).string(message.mediaType);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mediaType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<NamedFile>): NamedFile {
    return NamedFile.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<NamedFile>): NamedFile {
    const message = createBaseNamedFile();
    message.data = (object.data !== undefined && object.data !== null)
      ? BytesOrHex.fromPartial(object.data)
      : undefined;
    message.mediaType = object.mediaType ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAvatar(): Avatar {
  return { urlOrImage: undefined };
}

export const Avatar = {
  encode(message: Avatar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.urlOrImage?.$case) {
      case "url":
        writer.uint32(10).string(message.urlOrImage.url);
        break;
      case "image":
        NamedFile.encode(message.urlOrImage.image, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Avatar {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvatar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.urlOrImage = { $case: "url", url: reader.string() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.urlOrImage = { $case: "image", image: NamedFile.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Avatar>): Avatar {
    return Avatar.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Avatar>): Avatar {
    const message = createBaseAvatar();
    if (object.urlOrImage?.$case === "url" && object.urlOrImage?.url !== undefined && object.urlOrImage?.url !== null) {
      message.urlOrImage = { $case: "url", url: object.urlOrImage.url };
    }
    if (
      object.urlOrImage?.$case === "image" &&
      object.urlOrImage?.image !== undefined &&
      object.urlOrImage?.image !== null
    ) {
      message.urlOrImage = { $case: "image", image: NamedFile.fromPartial(object.urlOrImage.image) };
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp2 {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp2): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
