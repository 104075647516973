// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: modem.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  Area,
  BytesOrHex,
  Duration,
  Filter_ChildOrganizations,
  Filter_DeviceTypes,
  Filter_ModemIdentifiers,
  Filter_Modems,
  Filter_SensorBrands,
  Location,
  LocationSelection,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
} from "./base";
import { Struct } from "./google/protobuf/struct";
import { HealthLevel } from "./health";
import { OrganizationSelection } from "./organization";
import { Tag, TagSelection, UpdateTagsForItem } from "./tag";
import { Value_Numeric_Rate } from "./value";

export const protobufPackage = "hiber.modem";

/**
 * Modem and message management.
 *
 * Modems are anything capable of sending messages to the system. They have a unique modem number,
 * used to identify them.
 */

/**
 * Modem data, including location and last message (if available).
 * Location, last message and firmware version can be updated by messages, the rest is typically either set
 * when the modem is registered into the system or when a subscription is authorized.
 */
export interface Modem {
  /** An 8-character hexadecimal string */
  number: string;
  organization: string;
  /** An optional descriptor given to the modem */
  name: string;
  location: Location | undefined;
  lastMessageId: number;
  /** Time the server has received the last message. */
  lastMessageReceivedAt:
    | Timestamp
    | undefined;
  /** Time the modem has sent the last message. */
  lastMessageSentAt:
    | Timestamp
    | undefined;
  /** The body of the last message. */
  lastMessageBody:
    | BytesOrHex
    | undefined;
  /** The amount of time since the last message from this modem was received on the server. */
  inactivity:
    | Duration
    | undefined;
  /** Health level based on the modem alarm and some always-present alarms. */
  healthLevel: HealthLevel | undefined;
  lifecycle: Modem_Lifecycle;
  /** additional information */
  technical: Modem_TechnicalData | undefined;
  peripherals:
    | Modem_Peripherals
    | undefined;
  /** Notes field that can be used to add additional information to a modem. */
  notes: string;
  /** Secure notes field that can be used to add additional information to a modem, with limited accessibility. */
  secureNotes: string;
  tags: Tag[];
  /**
   * [DEPRECATED] Whether the modem is a gateway, it has been configured as a gateway and has connected devices.
   * Use `type` instead.
   *
   * @deprecated
   */
  isGateway: boolean;
  /**
   * [DEPRECATED] Whether the modem is connected to a modem configured as a gateway.
   * Use `type` instead.
   *
   * @deprecated
   */
  isDeviceConnectedToGateway: boolean;
  /**
   * [DEPRECATED] The modem number that this modem is connected to, if any.
   * Use `connected_device_info.connected_to_gateway` instead.
   *
   * @deprecated
   */
  connectedToGateway: string;
  /**
   * [DEPRECATED] External device ids, if any.
   * Use `connected_device_info.external_device_ids` instead.
   *
   * @deprecated
   */
  externalDeviceIds: string[];
  /**
   * The type of modem.
   * Used mainly to differentiate in the UI or to sort on.
   */
  type: Modem_Type;
  /** Additional information when this modem is a gateway. */
  gatewayInfo:
    | Modem_GatewayInfo
    | undefined;
  /** Additional information when this modem is a connected device. */
  connectedDeviceInfo:
    | Modem_ConnectedDeviceInfo
    | undefined;
  /** Modem metadata, typically extracted from messages. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /** The timezone configured for the modem. */
  timeZone: string;
  /** The transmission interval for this modem, if configured. */
  transmissionInterval:
    | Duration
    | undefined;
  /** The expected transmission rate for this modem. */
  expectedTransmissionRate?: Value_Numeric_Rate | undefined;
}

export enum Modem_Lifecycle {
  /**
   * ACCEPTANCE_TESTING - Device is being tested by Hiber.
   * Devices in this state are not visible to customers.
   */
  ACCEPTANCE_TESTING = "ACCEPTANCE_TESTING",
  /**
   * READY_TO_INSTALL - Device has passed Acceptance Testing and is ready be installed.
   * When it sends it first message, it will automatically go to INSTALLED.
   * Devices in this state are not visible to customers.
   */
  READY_TO_INSTALL = "READY_TO_INSTALL",
  /** INSTALLED - Device is active and sending messages. */
  INSTALLED = "INSTALLED",
  /**
   * PAUSED - Device is paused and not sending messages.
   * This should be of a temporary nature (e.g. a change to the installation is being made).
   * On its next message, it will automatically go back to INSTALLED.
   * Offline alarm checks will not be triggered for devices in this lifecycle.
   */
  PAUSED = "PAUSED",
  /**
   * DISABLED - Device is disabled and not sending messages.
   * This is a more permanent version of PAUSED.
   * Devices in this state are not visible to customers.
   */
  DISABLED = "DISABLED",
  /**
   * DECOMMISSIONED - Device is (going to be) removed from installation and will not return to installed status again.
   * Devices in this state are not visible to customers.
   */
  DECOMMISSIONED = "DECOMMISSIONED",
  /**
   * DEFECTIVE - Device is defective and should not be used anymore.
   * Devices in this state are typically RMA-ed and (should be) transferred to the RMA organization.
   * Devices in this state are not visible to customers.
   */
  DEFECTIVE = "DEFECTIVE",
  /** SPARE - Spare device sent to customer in case it is needed. */
  SPARE = "SPARE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function modem_LifecycleFromJSON(object: any): Modem_Lifecycle {
  switch (object) {
    case 0:
    case "ACCEPTANCE_TESTING":
      return Modem_Lifecycle.ACCEPTANCE_TESTING;
    case 8:
    case "READY_TO_INSTALL":
      return Modem_Lifecycle.READY_TO_INSTALL;
    case 1:
    case "INSTALLED":
      return Modem_Lifecycle.INSTALLED;
    case 6:
    case "PAUSED":
      return Modem_Lifecycle.PAUSED;
    case 5:
    case "DISABLED":
      return Modem_Lifecycle.DISABLED;
    case 4:
    case "DECOMMISSIONED":
      return Modem_Lifecycle.DECOMMISSIONED;
    case 7:
    case "DEFECTIVE":
      return Modem_Lifecycle.DEFECTIVE;
    case 9:
    case "SPARE":
      return Modem_Lifecycle.SPARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Modem_Lifecycle.UNRECOGNIZED;
  }
}

export function modem_LifecycleToNumber(object: Modem_Lifecycle): number {
  switch (object) {
    case Modem_Lifecycle.ACCEPTANCE_TESTING:
      return 0;
    case Modem_Lifecycle.READY_TO_INSTALL:
      return 8;
    case Modem_Lifecycle.INSTALLED:
      return 1;
    case Modem_Lifecycle.PAUSED:
      return 6;
    case Modem_Lifecycle.DISABLED:
      return 5;
    case Modem_Lifecycle.DECOMMISSIONED:
      return 4;
    case Modem_Lifecycle.DEFECTIVE:
      return 7;
    case Modem_Lifecycle.SPARE:
      return 9;
    case Modem_Lifecycle.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * The effective type of this modem.
 * Type can depend on the hardware itself as well as network topology.
 */
export enum Modem_Type {
  /** OTHER - A device of which the specific type is not known */
  OTHER = "OTHER",
  /** DISCONNECTED_SENSOR - A device that is not currently connected to a gateway. */
  DISCONNECTED_SENSOR = "DISCONNECTED_SENSOR",
  /**
   * GATEWAY - A device that can receive messages from sensors in the field and relay them (directly) to the satellite.
   * Typically a LoRaWAN hub.
   * Note that gateways also send messages themselves (e.g. a daily heartbeat).
   */
  GATEWAY = "GATEWAY",
  /** SENSOR - A sensor that can (only) send data to a gateway. Typically using a LoRaWAN connection. */
  SENSOR = "SENSOR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function modem_TypeFromJSON(object: any): Modem_Type {
  switch (object) {
    case 0:
    case "OTHER":
      return Modem_Type.OTHER;
    case 1:
    case "DISCONNECTED_SENSOR":
      return Modem_Type.DISCONNECTED_SENSOR;
    case 2:
    case "GATEWAY":
      return Modem_Type.GATEWAY;
    case 3:
    case "SENSOR":
      return Modem_Type.SENSOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Modem_Type.UNRECOGNIZED;
  }
}

export function modem_TypeToNumber(object: Modem_Type): number {
  switch (object) {
    case Modem_Type.OTHER:
      return 0;
    case Modem_Type.DISCONNECTED_SENSOR:
      return 1;
    case Modem_Type.GATEWAY:
      return 2;
    case Modem_Type.SENSOR:
      return 3;
    case Modem_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Additional information when this modem is a gateway. */
export interface Modem_GatewayInfo {
  numberOfConnectedDevices: number;
  /** Device type for this gateway. */
  deviceType: string;
  /** Brand for this gateway. */
  gatewayBrand: string;
}

/** Additional information when this modem is a connected device. */
export interface Modem_ConnectedDeviceInfo {
  /**
   * The gateways that this modem is connected to.
   * This field reflects the gateway that processed the last message for this modem.
   * If the modem is connected to multiple gateways, the last used gateway is tracked here.
   */
  connectedToGateway: string;
  /** External device ids for this modem. */
  externalDeviceIds: string[];
  /** Device type for this modem. */
  deviceType: string;
  /** Brand for this modem's sensor. */
  sensorBrand: string;
}

export interface Modem_TechnicalData {
  hardwareProductionBatch: string;
  manufacturer: string;
}

/**
 * Peripherals attached to the modem, including antenna, whether it has a gps antenna and an
 * open field for peripherals like battery, sensors, etc.
 */
export interface Modem_Peripherals {
  gps: boolean;
  peripherals: { [key: string]: string };
  customAntenna: string;
}

export interface Modem_Peripherals_PeripheralsEntry {
  key: string;
  value: string;
}

/**
 * Selection object for modems.
 * Filter modems by modem id, (child)organization, tags, activation status and time, service type and last message time.
 */
export interface ModemSelection {
  modems?: Filter_Modems | undefined;
  freeTextSearch?:
    | string
    | undefined;
  /**
   * Use lifecycle filter instead.
   *
   * @deprecated
   */
  onlyActive?:
    | boolean
    | undefined;
  /** @deprecated */
  activatedIn?: TimeRange | undefined;
  withLastMessageIn?:
    | TimeRange
    | undefined;
  /** Filter modems by health level. */
  healthLevels: string[];
  /**
   * Filter modems by lifecycle(s).
   * Defaults to nominal lifecycles, excluding disabled or decommissioned modems.
   */
  lifecycles: Modem_Lifecycle[];
  transfers?:
    | ModemSelection_Transfers
    | undefined;
  /**
   * Only include modems that have a type listed in types.
   * In other words, when providing multiple types, this is an "OR" relationship.
   */
  includeTypes: Modem_Type[];
  /** Exclude modems that have a type listed in types. */
  excludeTypes: Modem_Type[];
  deviceTypes?: Filter_DeviceTypes | undefined;
  sensorBrands?: Filter_SensorBrands | undefined;
  identifiers?:
    | Filter_ModemIdentifiers
    | undefined;
  /**
   * [DEPRECATED] Only list devices that are a gateway.
   * Replaced by `types`.
   * If you only want to have gateways in the result, create a selection with only `Modem.Type.GATEWAY` for `types`.
   *
   * @deprecated
   */
  onlyGateways?:
    | boolean
    | undefined;
  /**
   * [DEPRECATED] Only list devices that are a connected devices. Typically these are LoRaWAN sensors.
   * Replaced by `types`.
   * If you only want to have connected devices in the result,
   * create a selection with only `Modem.Type.CONNECTED_DEVICE` for `types`.
   *
   * @deprecated
   */
  onlyHasExternalDeviceIds?: boolean | undefined;
  connectedToGateways?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  externalDeviceIds: string[];
  filterByTags?: TagSelection | undefined;
  peripheralSelection?:
    | { $case: "peripherals"; peripherals: ModemSelection_Peripherals }
    | { $case: "onlyWithoutPeripheral"; onlyWithoutPeripheral: boolean }
    | undefined;
  /** Only select modems that are connected to a gateway (connected devices). */
  onlyConnectedToGateway?:
    | boolean
    | undefined;
  /** Only select modems that are not connected to a gateway (i.e. gateways). */
  notConnectedToGateway?: boolean | undefined;
}

export interface ModemSelection_Transfers {
  transfersIdentifiers: string[];
}

/**
 * Filter to (de)select modems based on their peripherals.
 *
 * For example:
 * - include { 'bluetooth' -> [ ] }
 *     returns all modems that have any version of bluetooth,
 * - include { 'bluetooth' -> [ '4.0', '5.0' ] }
 *     will only return modems that have bluetooth version 4.0 _or_ 5.0,
 * - include { 'bluetooth' -> [ '' ] }
 *     would only select bluetooth peripherals that don't have any version set,
 * - include { 'bluetooth' -> [ ], 'LoRaWAN' -> [ ] }
 *     will only select modems that have both bluetooth (any version) _and_ LoRaWAN (any version),
 * - include { 'bluetooth' -> [ ] }, exclude { 'bluetooth' -> [ ] }
 *     will return an empty list since exclude will take precedence, and
 * - include { 'bluetooth' -> [ ] }, exclude { 'bluetooth' -> [ '3.0' ] }
 *     returns modems that have bluetooth, but not version 3.0.
 * - exclude { 'bluetooth' -> [ ] }
 *     returns only modems that do not have any version of bluetooth,
 * - exclude { 'bluetooth' -> [ '4.0', '5.0' ] }
 *     returns modems that might have bluetooth as long as it's not versions 4.0 or 5.0,
 * - exclude { 'bluetooth' -> [ '' ] }
 *     returns modems that might have bluetooth as long as it's version is set to a specific value,
 * - exclude { 'bluetooth' -> [ ], 'LoRaWAN' -> [ ] }
 *     returns modems that don't have bluetooth and/or LoRaWAN.
 * - include { 'bluetooth' -> [ ] }, exclude { bluetooth' -> [ ] }
 *     will return an empty list, since exclusion takes precedence, and
 * - include { 'bluetooth' -> [ ] }, exclude { bluetooth' -> [ '3.0' ] }
 *     returns only modems that have bluetooth, but not version 3.0
 */
export interface ModemSelection_Peripherals {
  /**
   * Filter to only include modems with all of the given set of peripherals.
   * Peripherals are stored as a name-value pair (e.g. bluetooth, 4.0 / bluetooth, BLE).
   * To select for multiple versions of a peripheral,
   * add the name of the peripheral as a map-key and add a repeated list of versions as the map-value.
   */
  includeAnd: { [key: string]: ModemSelection_Peripherals_OneOfValues };
  /**
   * Filter to exclude modems with any of the given set of peripherals.
   * Peripherals are stored as a name-value pair (e.g. bluetooth, 4.0 / bluetooth, BLE).
   * To select for multiple versions of a peripheral,
   * add the name of the peripheral as a map-key and add a repeated list of versions as the map-value.
   */
  exclude: { [key: string]: ModemSelection_Peripherals_OneOfValues };
}

export interface ModemSelection_Peripherals_OneOfValues {
  value: string[];
}

export interface ModemSelection_Peripherals_IncludeAndEntry {
  key: string;
  value: ModemSelection_Peripherals_OneOfValues | undefined;
}

export interface ModemSelection_Peripherals_ExcludeEntry {
  key: string;
  value: ModemSelection_Peripherals_OneOfValues | undefined;
}

/**
 * Decrypted modem message. Messages are received encrypted and decrypted asynchronously, which adds the location
 * data and message body. (Your message body is, of course, still encrypted if you've encrypted it yourself)
 *
 * @deprecated
 */
export interface ModemMessage {
  /** Unique message identifier. */
  messageId: number;
  /** Modem number of the modem that sent the message. */
  modemNumber: string;
  /** The name of the modem that sent the message. */
  modemName: string;
  /** The device identifier for the modem that sent the message (e.g. a MAC address). */
  modemIdentifier: string;
  /** Time the message was sent from the modem. */
  sentAt:
    | Timestamp
    | undefined;
  /** Time the message was received on the server. */
  receivedAt:
    | Timestamp
    | undefined;
  /** Modem location when the message was sent. */
  location:
    | Location
    | undefined;
  /** Message body. */
  body: Uint8Array;
  /** Message body convenience object. */
  bodyBytes:
    | BytesOrHex
    | undefined;
  /** A list of applied body parsers and their results. */
  bodyParsed: ModemMessage_ParsedBody[];
  /** Convenience flag marking whether the body was parsed successfully by at least one parser. */
  bodyParsedSuccessfully: boolean;
  /** Message source(s) for this message (i.e. it was received through the satellite or directly to the server). */
  sources: ModemMessage_Source[];
  /** True if the the TEST source is in the sources. */
  isTest: boolean;
  /** Whether this message contains other messages. */
  isGatewayMessage: boolean;
  /** The gateway message this message was sent in. */
  viaGatewayMessage: number;
  /** Message metadata, defined by the modem or gateway. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /**
   * Flattened results of all successful body parsers.
   * This is a convenience to access the fields from body_parsed, but if any fields are present in
   * multiple body_parsed results, it is not defined which field will be used in this Struct.
   * This may change in the future.
   */
  bodyFields:
    | { [key: string]: any }
    | undefined;
  /** The tags of the modem that sent the message. */
  tags: Tag[];
  /** The names of the tags of the modem that sent the message. */
  tagNames: string[];
}

export enum ModemMessage_Source {
  /** HIBERBAND - A real message from a modem or gateway, sent over Hiberband to the server. */
  HIBERBAND = "HIBERBAND",
  /** DIRECT_TO_API - A real message from a modem or gateway, sent directly to the API using a persistent connection. */
  DIRECT_TO_API = "DIRECT_TO_API",
  /** TEST - A test message sent to the testing API. */
  TEST = "TEST",
  /** SIMULATION - A simulated message, generated by the server. */
  SIMULATION = "SIMULATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function modemMessage_SourceFromJSON(object: any): ModemMessage_Source {
  switch (object) {
    case 0:
    case "HIBERBAND":
      return ModemMessage_Source.HIBERBAND;
    case 1:
    case "DIRECT_TO_API":
      return ModemMessage_Source.DIRECT_TO_API;
    case 2:
    case "TEST":
      return ModemMessage_Source.TEST;
    case 3:
    case "SIMULATION":
      return ModemMessage_Source.SIMULATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModemMessage_Source.UNRECOGNIZED;
  }
}

export function modemMessage_SourceToNumber(object: ModemMessage_Source): number {
  switch (object) {
    case ModemMessage_Source.HIBERBAND:
      return 0;
    case ModemMessage_Source.DIRECT_TO_API:
      return 1;
    case ModemMessage_Source.TEST:
      return 2;
    case ModemMessage_Source.SIMULATION:
      return 3;
    case ModemMessage_Source.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Parsed message body.
 * If any parsers are configured for the modem, they are applied to the message.
 * The result is stored either as a json object or an error string.
 */
export interface ModemMessage_ParsedBody {
  /**
   * Id of the parser that was used, if the parser and modem are owned by the same organization.
   * [DEPRECATED] Deprecated in favour of the identifier, which is globally unique.
   *
   * @deprecated
   */
  parserId: number;
  /** Globally unique identifier of the parser that was used. */
  parserIdentifier: string;
  /** Name of the parser that was used. */
  parserName: string;
  parsed?:
    | { $case: "error"; error: string }
    | { $case: "result"; result: { [key: string]: any } | undefined }
    | undefined;
}

/**
 * Selection object for modem messages.
 * Filter messages by modem id, (child)organization, and time sent (note that this is not the time the message was received)
 *
 * @deprecated
 */
export interface ModemMessageSelection {
  /**
   * Define the modems to return messages for, i.e. include = [AAAA AAAA, BBBB BBBB].
   * Deprecated in favor of using the ModemSelection.
   *
   * @deprecated
   */
  modems:
    | Filter_Modems
    | undefined;
  /** Select the modems to return messages for. */
  modemSelection:
    | ModemSelection
    | undefined;
  /** Filter message by time range. This field is required, to limit the amount of messages. */
  timeRange:
    | TimeRange
    | undefined;
  /**
   * Filter messages by the given source filter.
   * For example, to exclude test and simulated messages, use exclude = [TEST, SIMULATED]
   * or to only return Hiberband messages, use include = [HIBERBAND].
   * Note that if a message has sources [HIBERBAND, DIRECT_TO_API], including [HIBERBAND] will include the message,
   * and excluding [HIBERBAND] will filter out the message, even though it also has DIRECT_TO_API.
   */
  filterBySources: ModemMessageSelection_ModemMessageSourceFilter | undefined;
}

export interface ModemMessageSelection_ModemMessageSourceFilter {
  include: ModemMessage_Source[];
  exclude: ModemMessage_Source[];
}

export interface GetModemRequest {
  where?:
    | { $case: "organization"; organization: string }
    | { $case: "inOrganizations"; inOrganizations: OrganizationSelection }
    | undefined;
  /** The modem to get. */
  modemNumber: string;
  /**
   * Look for the modem in child organizations.
   * DEPRECATED: use the in_organizations flag instead.
   *
   * @deprecated
   */
  childOrganizations?: Filter_ChildOrganizations | undefined;
}

export interface ListModemsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which modems to return. Optional, when omitted or empty everything is included. */
  selection?:
    | ModemSelection
    | undefined;
  /** Paginate through results. */
  pagination?:
    | Pagination
    | undefined;
  /** Sort the modem with the given sort options. */
  sortBy: ListModemsRequest_Sort[];
  /** Include modems from the selected child organizations. */
  childOrganizations?:
    | Filter_ChildOrganizations
    | undefined;
  /** Filter modems by location. */
  locationSelection?:
    | LocationSelection
    | undefined;
  /**
   * Set this to true to populate the group_parents field in the response.
   * This will be populated with missing group parents (i.e. gateways) for the the modems on this page.
   * Any group parent that is on the current page is not included in this list to avoid duplicate data.
   */
  includeMissingGroupParents?: boolean | undefined;
}

/** Sorting options for the results. */
export enum ListModemsRequest_Sort {
  /** LAST_MESSAGE_RECEIVED - Sorts messages in descending time order. */
  LAST_MESSAGE_RECEIVED = "LAST_MESSAGE_RECEIVED",
  /** LAST_MESSAGE_RECEIVED_INVERTED - Sorts messages in ascending time order. */
  LAST_MESSAGE_RECEIVED_INVERTED = "LAST_MESSAGE_RECEIVED_INVERTED",
  /** MODEM_NUMBER_ASC - Sort numerically on the number of the modem, in ascending order. */
  MODEM_NUMBER_ASC = "MODEM_NUMBER_ASC",
  /** MODEM_NUMBER_DESC - Sort numerically on the number of the modem, in descending order. */
  MODEM_NUMBER_DESC = "MODEM_NUMBER_DESC",
  /** STATUS_ASC - Sort modem on its Status. */
  STATUS_ASC = "STATUS_ASC",
  /** STATUS_DESC - Sort modem on its Status in reverse order. */
  STATUS_DESC = "STATUS_DESC",
  /** STATUS_ASC_ALPHABETICAL - Status sorted alphabetically by Status name. */
  STATUS_ASC_ALPHABETICAL = "STATUS_ASC_ALPHABETICAL",
  /** STATUS_DESC_ALPHABETICAL - Status sorted alphabetically by Status name, descending order. */
  STATUS_DESC_ALPHABETICAL = "STATUS_DESC_ALPHABETICAL",
  /** MODEM_NAME_ASC - Sort alphabetically on the name of the modem. De default name of the modem is its HEX number, in ascending order. */
  MODEM_NAME_ASC = "MODEM_NAME_ASC",
  /** MODEM_NAME_DESC - Sort alphabetically on the name of the modem. De default name of the modem is its HEX number, in descending order. */
  MODEM_NAME_DESC = "MODEM_NAME_DESC",
  /** ORGANIZATION_ASC - Sort alphabetically on the name of the organization that owns the modem, in ascending order. */
  ORGANIZATION_ASC = "ORGANIZATION_ASC",
  /** ORGANIZATION_DESC - Sort alphabetically on the name of the organization that owns the modem, in descending order. */
  ORGANIZATION_DESC = "ORGANIZATION_DESC",
  /** HEALTH - Health sorted from least to most severe (i.e. OK, WARNING, ERROR). */
  HEALTH = "HEALTH",
  /** HEALTH_DESC - Health sorted from most to least severe (i.e. ERROR, WARNING, OK). */
  HEALTH_DESC = "HEALTH_DESC",
  /** HEALTH_ASC_ALPHABETICAL - Health sorted alphabetically by health level name. */
  HEALTH_ASC_ALPHABETICAL = "HEALTH_ASC_ALPHABETICAL",
  /** HEALTH_DESC_ALPHABETICAL - Health sorted alphabetically by health level name, descending order. */
  HEALTH_DESC_ALPHABETICAL = "HEALTH_DESC_ALPHABETICAL",
  /** SENSOR_BRAND_ASC - Sort alphabetically on the brand of the sensor, in ascending order. */
  SENSOR_BRAND_ASC = "SENSOR_BRAND_ASC",
  /** SENSOR_BRAND_DESC - Sort alphabetically on the brand of the sensor, in descending order. */
  SENSOR_BRAND_DESC = "SENSOR_BRAND_DESC",
  /** DEVICE_TYPE_ASC - Sort alphabetically on the device type, in ascending order. */
  DEVICE_TYPE_ASC = "DEVICE_TYPE_ASC",
  /** DEVICE_TYPE_DESC - Sort alphabetically on the device type, in descending order. */
  DEVICE_TYPE_DESC = "DEVICE_TYPE_DESC",
  /** TAG_TYPE_WELL_ASC - Sort alphabetically on any tags of type 'well', in ascending order. */
  TAG_TYPE_WELL_ASC = "TAG_TYPE_WELL_ASC",
  /** TAG_TYPE_WELL_DESC - Sort alphabetically on any tags of type 'well', in descending order. */
  TAG_TYPE_WELL_DESC = "TAG_TYPE_WELL_DESC",
  /** TAG_TYPE_SITE_ASC - Sort alphabetically on any tags of type 'site', in ascending order. */
  TAG_TYPE_SITE_ASC = "TAG_TYPE_SITE_ASC",
  /** TAG_TYPE_SITE_DESC - Sort alphabetically on any tags of type 'site', in descending order. */
  TAG_TYPE_SITE_DESC = "TAG_TYPE_SITE_DESC",
  /** TAG_TYPE_PRODUCTION_AREA_ASC - Sort alphabetically on any tags of type 'production_area', in ascending order. */
  TAG_TYPE_PRODUCTION_AREA_ASC = "TAG_TYPE_PRODUCTION_AREA_ASC",
  /** TAG_TYPE_PRODUCTION_AREA_DESC - Sort alphabetically on any tags of type 'production_area', in descending order. */
  TAG_TYPE_PRODUCTION_AREA_DESC = "TAG_TYPE_PRODUCTION_AREA_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listModemsRequest_SortFromJSON(object: any): ListModemsRequest_Sort {
  switch (object) {
    case 0:
    case "LAST_MESSAGE_RECEIVED":
      return ListModemsRequest_Sort.LAST_MESSAGE_RECEIVED;
    case 1:
    case "LAST_MESSAGE_RECEIVED_INVERTED":
      return ListModemsRequest_Sort.LAST_MESSAGE_RECEIVED_INVERTED;
    case 2:
    case "MODEM_NUMBER_ASC":
      return ListModemsRequest_Sort.MODEM_NUMBER_ASC;
    case 3:
    case "MODEM_NUMBER_DESC":
      return ListModemsRequest_Sort.MODEM_NUMBER_DESC;
    case 4:
    case "STATUS_ASC":
      return ListModemsRequest_Sort.STATUS_ASC;
    case 5:
    case "STATUS_DESC":
      return ListModemsRequest_Sort.STATUS_DESC;
    case 14:
    case "STATUS_ASC_ALPHABETICAL":
      return ListModemsRequest_Sort.STATUS_ASC_ALPHABETICAL;
    case 15:
    case "STATUS_DESC_ALPHABETICAL":
      return ListModemsRequest_Sort.STATUS_DESC_ALPHABETICAL;
    case 6:
    case "MODEM_NAME_ASC":
      return ListModemsRequest_Sort.MODEM_NAME_ASC;
    case 7:
    case "MODEM_NAME_DESC":
      return ListModemsRequest_Sort.MODEM_NAME_DESC;
    case 8:
    case "ORGANIZATION_ASC":
      return ListModemsRequest_Sort.ORGANIZATION_ASC;
    case 9:
    case "ORGANIZATION_DESC":
      return ListModemsRequest_Sort.ORGANIZATION_DESC;
    case 10:
    case "HEALTH":
      return ListModemsRequest_Sort.HEALTH;
    case 11:
    case "HEALTH_DESC":
      return ListModemsRequest_Sort.HEALTH_DESC;
    case 12:
    case "HEALTH_ASC_ALPHABETICAL":
      return ListModemsRequest_Sort.HEALTH_ASC_ALPHABETICAL;
    case 13:
    case "HEALTH_DESC_ALPHABETICAL":
      return ListModemsRequest_Sort.HEALTH_DESC_ALPHABETICAL;
    case 16:
    case "SENSOR_BRAND_ASC":
      return ListModemsRequest_Sort.SENSOR_BRAND_ASC;
    case 17:
    case "SENSOR_BRAND_DESC":
      return ListModemsRequest_Sort.SENSOR_BRAND_DESC;
    case 18:
    case "DEVICE_TYPE_ASC":
      return ListModemsRequest_Sort.DEVICE_TYPE_ASC;
    case 19:
    case "DEVICE_TYPE_DESC":
      return ListModemsRequest_Sort.DEVICE_TYPE_DESC;
    case 20:
    case "TAG_TYPE_WELL_ASC":
      return ListModemsRequest_Sort.TAG_TYPE_WELL_ASC;
    case 21:
    case "TAG_TYPE_WELL_DESC":
      return ListModemsRequest_Sort.TAG_TYPE_WELL_DESC;
    case 22:
    case "TAG_TYPE_SITE_ASC":
      return ListModemsRequest_Sort.TAG_TYPE_SITE_ASC;
    case 23:
    case "TAG_TYPE_SITE_DESC":
      return ListModemsRequest_Sort.TAG_TYPE_SITE_DESC;
    case 24:
    case "TAG_TYPE_PRODUCTION_AREA_ASC":
      return ListModemsRequest_Sort.TAG_TYPE_PRODUCTION_AREA_ASC;
    case 25:
    case "TAG_TYPE_PRODUCTION_AREA_DESC":
      return ListModemsRequest_Sort.TAG_TYPE_PRODUCTION_AREA_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListModemsRequest_Sort.UNRECOGNIZED;
  }
}

export function listModemsRequest_SortToNumber(object: ListModemsRequest_Sort): number {
  switch (object) {
    case ListModemsRequest_Sort.LAST_MESSAGE_RECEIVED:
      return 0;
    case ListModemsRequest_Sort.LAST_MESSAGE_RECEIVED_INVERTED:
      return 1;
    case ListModemsRequest_Sort.MODEM_NUMBER_ASC:
      return 2;
    case ListModemsRequest_Sort.MODEM_NUMBER_DESC:
      return 3;
    case ListModemsRequest_Sort.STATUS_ASC:
      return 4;
    case ListModemsRequest_Sort.STATUS_DESC:
      return 5;
    case ListModemsRequest_Sort.STATUS_ASC_ALPHABETICAL:
      return 14;
    case ListModemsRequest_Sort.STATUS_DESC_ALPHABETICAL:
      return 15;
    case ListModemsRequest_Sort.MODEM_NAME_ASC:
      return 6;
    case ListModemsRequest_Sort.MODEM_NAME_DESC:
      return 7;
    case ListModemsRequest_Sort.ORGANIZATION_ASC:
      return 8;
    case ListModemsRequest_Sort.ORGANIZATION_DESC:
      return 9;
    case ListModemsRequest_Sort.HEALTH:
      return 10;
    case ListModemsRequest_Sort.HEALTH_DESC:
      return 11;
    case ListModemsRequest_Sort.HEALTH_ASC_ALPHABETICAL:
      return 12;
    case ListModemsRequest_Sort.HEALTH_DESC_ALPHABETICAL:
      return 13;
    case ListModemsRequest_Sort.SENSOR_BRAND_ASC:
      return 16;
    case ListModemsRequest_Sort.SENSOR_BRAND_DESC:
      return 17;
    case ListModemsRequest_Sort.DEVICE_TYPE_ASC:
      return 18;
    case ListModemsRequest_Sort.DEVICE_TYPE_DESC:
      return 19;
    case ListModemsRequest_Sort.TAG_TYPE_WELL_ASC:
      return 20;
    case ListModemsRequest_Sort.TAG_TYPE_WELL_DESC:
      return 21;
    case ListModemsRequest_Sort.TAG_TYPE_SITE_ASC:
      return 22;
    case ListModemsRequest_Sort.TAG_TYPE_SITE_DESC:
      return 23;
    case ListModemsRequest_Sort.TAG_TYPE_PRODUCTION_AREA_ASC:
      return 24;
    case ListModemsRequest_Sort.TAG_TYPE_PRODUCTION_AREA_DESC:
      return 25;
    case ListModemsRequest_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListModemsRequest_Response {
  modems: Modem[];
  request: ListModemsRequest | undefined;
  pagination: Pagination_Result | undefined;
  sortedBy: ListModemsRequest_Sort[];
  /**
   * This will be populated with missing group parents (i.e. gateways) for the the modems on this page.
   * Any group parent that is on the current page is not included in this list to avoid duplicate data.
   * Only set when include_missing_group_parents is true in the request.
   */
  groupParents: Modem[];
}

/**
 * Lists all modems the given criteria, grouped by gateway.
 * Pagination is applied to modems in a group, not to the groups themselves.
 */
export interface ListModemsGrouped {
}

/**
 * The usage of ListModemsRequest and ListModemsRequest.Response in the Response.Group was intentionally
 * chosen to simplify pagination withing a group, since the response contains the request
 * (which will be updated with the group it is in) and pagination to select the next page using the
 * list method.
 */
export interface ListModemsGrouped_Request {
  /**
   * The modems you want to display in the groups.
   * This is a ListModemsRequest that is used to fetch the modems for each group, with the added limitation
   * that the modems must be in the group.
   */
  groupContent:
    | ListModemsRequest
    | undefined;
  /**
   * Select the parents for the groups to display.
   * Anything selected here that cannot have any connected modems (i.e. a direct modem) wil be omitted
   * unless allow_any_group_parent is set to true.
   * Only gateways will have connected devices, so unless allow_any_group_parent is true,
   * type is replaced with GATEWAY. This may change in the future if more grouping options are introduced.
   */
  groups:
    | ListModemsRequest
    | undefined;
  /**
   * Set to true to allow group request to return modems that can never be the parent of a group.
   * - If this flag is false, the modems for the groups are automatically filtered on being a parent of a group.
   *   Note that the group may still be empty, i.e. when a gateway has no connected devices.
   * - If this flag is true, the group parents can include modems which cannot be a parent and for which
   *   the group can only be empty.
   */
  allowAnyGroupParent?: boolean | undefined;
}

/**
 * The usage of ListModemsRequest and ListModemsRequest.Response in the Response.Group was intentionally
 * chosen to simplify pagination withing a group, since the response contains the request
 * (which will be updated with the group it is in) and pagination to select the next page using the
 * list method.
 */
export interface ListModemsGrouped_Response {
  /**
   * The groups, based on a parent (typically a gateway unless allow_any_group_parent was set).
   * This is a ListModemsRequest.Response, with the selection updated with its parent and includes
   * the group parent in the group_parents field.
   */
  groups: ListModemsRequest_Response[];
  request: ListModemsGrouped_Request | undefined;
  pagination: Pagination_Result | undefined;
  sortedBy: ListModemsRequest_Sort[];
  total: ListModemsGrouped_Response_Total | undefined;
}

export interface ListModemsGrouped_Response_Total {
  groups: number;
  content: number;
  all: number;
}

/** @deprecated */
export interface ListModemMessagesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
  selection: ModemMessageSelection | undefined;
  pagination: Pagination | undefined;
}

export interface ListModemMessagesRequest_Response {
  messages: ModemMessage[];
  request: ListModemMessagesRequest | undefined;
  pagination: Pagination_Result | undefined;
}

/**
 * Create modems for your organization.
 * This call is not available to all organizations, and is typically used to create modems for testing or
 * when you want to connect a device to the API using just the API calls in the TestingService.
 */
export interface CreateModem {
}

export interface CreateModem_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The amount of modems to create. */
  amount: number;
  /**
   * The name(s) to give the new modem(s).
   * Must not contain more values than the amount of modems to create.
   */
  names: string[];
  /**
   * The external device identifiers for the new modems.
   * Must not contain more values than the amount of modems to create.
   * The order of this list matches the order of the name, values in the same index are applied to the same modem.
   */
  externalDeviceIdentifiers: string[];
  /** The status for the new modems. */
  lifecycle?:
    | Modem_Lifecycle
    | undefined;
  /** The technical data, such as manufacturer and hardware information for the new modems. */
  technical?:
    | Modem_TechnicalData
    | undefined;
  /** The peripherals for the new modems. */
  peripherals: { [key: string]: string };
  /** Notes for all new modems. */
  notes?: string | undefined;
  initialLocation?:
    | { $case: "location"; location: Location }
    | { $case: "randomLocationInArea"; randomLocationInArea: Area }
    | undefined;
  /** The tags to set to the new modems, either existing or created by this call. */
  tags?:
    | UpdateTagsForItem
    | undefined;
  /** Whether to return the full Modem in addition to the modem number and verification code. */
  includeModemsInResponse?:
    | boolean
    | undefined;
  /** Whether to return the verification code for the modem. */
  includeVerificationCode?: boolean | undefined;
}

export interface CreateModem_Request_PeripheralsEntry {
  key: string;
  value: string;
}

export interface CreateModem_Response {
  modems: CreateModem_Response_CreatedModem[];
  request: CreateModem_Request | undefined;
}

export interface CreateModem_Response_CreatedModem {
  /** The modem number that was created. */
  modemNumber: string;
  /**
   * The verification code for this modem number, used to, for example, claim modems.
   * Only available when include_verification_code was set to true.
   */
  verificationCode: string;
  /** Only available when include_modems_in_response was set to true. */
  modem: Modem | undefined;
}

export interface UpdateModemTagsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  update: UpdateTagsForItem | undefined;
  selection: ModemSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface UpdateModemTagsRequest_Response {
  modems: Modem[];
  request: UpdateModemTagsRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface TagCount {
  tag: Tag | undefined;
  modems: number;
}

export interface TagCount_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the modems to count. Optional, when omitted or empty everything is included. */
  selection?:
    | ModemSelection
    | undefined;
  /** Select the tags to list. Optional, when omitted or empty everything is included. */
  tagSelection?: TagSelection | undefined;
}

export interface TagCount_Response {
  tags: TagCount[];
  request: TagCount_Request | undefined;
}

function createBaseModem(): Modem {
  return {
    number: "",
    organization: "",
    name: "",
    location: undefined,
    lastMessageId: 0,
    lastMessageReceivedAt: undefined,
    lastMessageSentAt: undefined,
    lastMessageBody: undefined,
    inactivity: undefined,
    healthLevel: undefined,
    lifecycle: Modem_Lifecycle.ACCEPTANCE_TESTING,
    technical: undefined,
    peripherals: undefined,
    notes: "",
    secureNotes: "",
    tags: [],
    isGateway: false,
    isDeviceConnectedToGateway: false,
    connectedToGateway: "",
    externalDeviceIds: [],
    type: Modem_Type.OTHER,
    gatewayInfo: undefined,
    connectedDeviceInfo: undefined,
    metadata: undefined,
    timeZone: "",
    transmissionInterval: undefined,
    expectedTransmissionRate: undefined,
  };
}

export const Modem = {
  encode(message: Modem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(34).fork()).ldelim();
    }
    if (message.lastMessageId !== 0) {
      writer.uint32(128).uint64(message.lastMessageId);
    }
    if (message.lastMessageReceivedAt !== undefined) {
      Timestamp.encode(message.lastMessageReceivedAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.lastMessageSentAt !== undefined) {
      Timestamp.encode(message.lastMessageSentAt, writer.uint32(114).fork()).ldelim();
    }
    if (message.lastMessageBody !== undefined) {
      BytesOrHex.encode(message.lastMessageBody, writer.uint32(122).fork()).ldelim();
    }
    if (message.inactivity !== undefined) {
      Duration.encode(message.inactivity, writer.uint32(242).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(226).fork()).ldelim();
    }
    if (message.lifecycle !== Modem_Lifecycle.ACCEPTANCE_TESTING) {
      writer.uint32(96).int32(modem_LifecycleToNumber(message.lifecycle));
    }
    if (message.technical !== undefined) {
      Modem_TechnicalData.encode(message.technical, writer.uint32(58).fork()).ldelim();
    }
    if (message.peripherals !== undefined) {
      Modem_Peripherals.encode(message.peripherals, writer.uint32(90).fork()).ldelim();
    }
    if (message.notes !== "") {
      writer.uint32(138).string(message.notes);
    }
    if (message.secureNotes !== "") {
      writer.uint32(146).string(message.secureNotes);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.isGateway !== false) {
      writer.uint32(160).bool(message.isGateway);
    }
    if (message.isDeviceConnectedToGateway !== false) {
      writer.uint32(168).bool(message.isDeviceConnectedToGateway);
    }
    if (message.connectedToGateway !== "") {
      writer.uint32(178).string(message.connectedToGateway);
    }
    for (const v of message.externalDeviceIds) {
      writer.uint32(186).string(v!);
    }
    if (message.type !== Modem_Type.OTHER) {
      writer.uint32(200).int32(modem_TypeToNumber(message.type));
    }
    if (message.gatewayInfo !== undefined) {
      Modem_GatewayInfo.encode(message.gatewayInfo, writer.uint32(210).fork()).ldelim();
    }
    if (message.connectedDeviceInfo !== undefined) {
      Modem_ConnectedDeviceInfo.encode(message.connectedDeviceInfo, writer.uint32(218).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(250).fork()).ldelim();
    }
    if (message.timeZone !== "") {
      writer.uint32(258).string(message.timeZone);
    }
    if (message.transmissionInterval !== undefined) {
      Duration.encode(message.transmissionInterval, writer.uint32(266).fork()).ldelim();
    }
    if (message.expectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedTransmissionRate, writer.uint32(274).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.lastMessageId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastMessageReceivedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.lastMessageSentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.lastMessageBody = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.inactivity = Duration.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.lifecycle = modem_LifecycleFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.technical = Modem_TechnicalData.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.peripherals = Modem_Peripherals.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.secureNotes = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.isGateway = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.isDeviceConnectedToGateway = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.connectedToGateway = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.externalDeviceIds.push(reader.string());
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.type = modem_TypeFromJSON(reader.int32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.gatewayInfo = Modem_GatewayInfo.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.connectedDeviceInfo = Modem_ConnectedDeviceInfo.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.transmissionInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.expectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem>): Modem {
    return Modem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem>): Modem {
    const message = createBaseModem();
    message.number = object.number ?? "";
    message.organization = object.organization ?? "";
    message.name = object.name ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.lastMessageId = object.lastMessageId ?? 0;
    message.lastMessageReceivedAt =
      (object.lastMessageReceivedAt !== undefined && object.lastMessageReceivedAt !== null)
        ? Timestamp.fromPartial(object.lastMessageReceivedAt)
        : undefined;
    message.lastMessageSentAt = (object.lastMessageSentAt !== undefined && object.lastMessageSentAt !== null)
      ? Timestamp.fromPartial(object.lastMessageSentAt)
      : undefined;
    message.lastMessageBody = (object.lastMessageBody !== undefined && object.lastMessageBody !== null)
      ? BytesOrHex.fromPartial(object.lastMessageBody)
      : undefined;
    message.inactivity = (object.inactivity !== undefined && object.inactivity !== null)
      ? Duration.fromPartial(object.inactivity)
      : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    message.lifecycle = object.lifecycle ?? Modem_Lifecycle.ACCEPTANCE_TESTING;
    message.technical = (object.technical !== undefined && object.technical !== null)
      ? Modem_TechnicalData.fromPartial(object.technical)
      : undefined;
    message.peripherals = (object.peripherals !== undefined && object.peripherals !== null)
      ? Modem_Peripherals.fromPartial(object.peripherals)
      : undefined;
    message.notes = object.notes ?? "";
    message.secureNotes = object.secureNotes ?? "";
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.isGateway = object.isGateway ?? false;
    message.isDeviceConnectedToGateway = object.isDeviceConnectedToGateway ?? false;
    message.connectedToGateway = object.connectedToGateway ?? "";
    message.externalDeviceIds = object.externalDeviceIds?.map((e) => e) || [];
    message.type = object.type ?? Modem_Type.OTHER;
    message.gatewayInfo = (object.gatewayInfo !== undefined && object.gatewayInfo !== null)
      ? Modem_GatewayInfo.fromPartial(object.gatewayInfo)
      : undefined;
    message.connectedDeviceInfo = (object.connectedDeviceInfo !== undefined && object.connectedDeviceInfo !== null)
      ? Modem_ConnectedDeviceInfo.fromPartial(object.connectedDeviceInfo)
      : undefined;
    message.metadata = object.metadata ?? undefined;
    message.timeZone = object.timeZone ?? "";
    message.transmissionInterval = (object.transmissionInterval !== undefined && object.transmissionInterval !== null)
      ? Duration.fromPartial(object.transmissionInterval)
      : undefined;
    message.expectedTransmissionRate =
      (object.expectedTransmissionRate !== undefined && object.expectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedTransmissionRate)
        : undefined;
    return message;
  },
};

function createBaseModem_GatewayInfo(): Modem_GatewayInfo {
  return { numberOfConnectedDevices: 0, deviceType: "", gatewayBrand: "" };
}

export const Modem_GatewayInfo = {
  encode(message: Modem_GatewayInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numberOfConnectedDevices !== 0) {
      writer.uint32(8).int32(message.numberOfConnectedDevices);
    }
    if (message.deviceType !== "") {
      writer.uint32(18).string(message.deviceType);
    }
    if (message.gatewayBrand !== "") {
      writer.uint32(26).string(message.gatewayBrand);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem_GatewayInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem_GatewayInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numberOfConnectedDevices = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gatewayBrand = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem_GatewayInfo>): Modem_GatewayInfo {
    return Modem_GatewayInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem_GatewayInfo>): Modem_GatewayInfo {
    const message = createBaseModem_GatewayInfo();
    message.numberOfConnectedDevices = object.numberOfConnectedDevices ?? 0;
    message.deviceType = object.deviceType ?? "";
    message.gatewayBrand = object.gatewayBrand ?? "";
    return message;
  },
};

function createBaseModem_ConnectedDeviceInfo(): Modem_ConnectedDeviceInfo {
  return { connectedToGateway: "", externalDeviceIds: [], deviceType: "", sensorBrand: "" };
}

export const Modem_ConnectedDeviceInfo = {
  encode(message: Modem_ConnectedDeviceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.connectedToGateway !== "") {
      writer.uint32(10).string(message.connectedToGateway);
    }
    for (const v of message.externalDeviceIds) {
      writer.uint32(18).string(v!);
    }
    if (message.deviceType !== "") {
      writer.uint32(26).string(message.deviceType);
    }
    if (message.sensorBrand !== "") {
      writer.uint32(34).string(message.sensorBrand);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem_ConnectedDeviceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem_ConnectedDeviceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.connectedToGateway = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalDeviceIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sensorBrand = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem_ConnectedDeviceInfo>): Modem_ConnectedDeviceInfo {
    return Modem_ConnectedDeviceInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem_ConnectedDeviceInfo>): Modem_ConnectedDeviceInfo {
    const message = createBaseModem_ConnectedDeviceInfo();
    message.connectedToGateway = object.connectedToGateway ?? "";
    message.externalDeviceIds = object.externalDeviceIds?.map((e) => e) || [];
    message.deviceType = object.deviceType ?? "";
    message.sensorBrand = object.sensorBrand ?? "";
    return message;
  },
};

function createBaseModem_TechnicalData(): Modem_TechnicalData {
  return { hardwareProductionBatch: "", manufacturer: "" };
}

export const Modem_TechnicalData = {
  encode(message: Modem_TechnicalData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hardwareProductionBatch !== "") {
      writer.uint32(50).string(message.hardwareProductionBatch);
    }
    if (message.manufacturer !== "") {
      writer.uint32(58).string(message.manufacturer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem_TechnicalData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem_TechnicalData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hardwareProductionBatch = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem_TechnicalData>): Modem_TechnicalData {
    return Modem_TechnicalData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem_TechnicalData>): Modem_TechnicalData {
    const message = createBaseModem_TechnicalData();
    message.hardwareProductionBatch = object.hardwareProductionBatch ?? "";
    message.manufacturer = object.manufacturer ?? "";
    return message;
  },
};

function createBaseModem_Peripherals(): Modem_Peripherals {
  return { gps: false, peripherals: {}, customAntenna: "" };
}

export const Modem_Peripherals = {
  encode(message: Modem_Peripherals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.gps !== false) {
      writer.uint32(16).bool(message.gps);
    }
    Object.entries(message.peripherals).forEach(([key, value]) => {
      Modem_Peripherals_PeripheralsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.customAntenna !== "") {
      writer.uint32(34).string(message.customAntenna);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem_Peripherals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem_Peripherals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.gps = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = Modem_Peripherals_PeripheralsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.peripherals[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.customAntenna = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem_Peripherals>): Modem_Peripherals {
    return Modem_Peripherals.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem_Peripherals>): Modem_Peripherals {
    const message = createBaseModem_Peripherals();
    message.gps = object.gps ?? false;
    message.peripherals = Object.entries(object.peripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.customAntenna = object.customAntenna ?? "";
    return message;
  },
};

function createBaseModem_Peripherals_PeripheralsEntry(): Modem_Peripherals_PeripheralsEntry {
  return { key: "", value: "" };
}

export const Modem_Peripherals_PeripheralsEntry = {
  encode(message: Modem_Peripherals_PeripheralsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Modem_Peripherals_PeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModem_Peripherals_PeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Modem_Peripherals_PeripheralsEntry>): Modem_Peripherals_PeripheralsEntry {
    return Modem_Peripherals_PeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Modem_Peripherals_PeripheralsEntry>): Modem_Peripherals_PeripheralsEntry {
    const message = createBaseModem_Peripherals_PeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseModemSelection(): ModemSelection {
  return {
    modems: undefined,
    freeTextSearch: undefined,
    onlyActive: undefined,
    activatedIn: undefined,
    withLastMessageIn: undefined,
    healthLevels: [],
    lifecycles: [],
    transfers: undefined,
    includeTypes: [],
    excludeTypes: [],
    deviceTypes: undefined,
    sensorBrands: undefined,
    identifiers: undefined,
    onlyGateways: undefined,
    onlyHasExternalDeviceIds: undefined,
    connectedToGateways: undefined,
    externalDeviceIds: [],
    filterByTags: undefined,
    peripheralSelection: undefined,
    onlyConnectedToGateway: undefined,
    notConnectedToGateway: undefined,
  };
}

export const ModemSelection = {
  encode(message: ModemSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modems !== undefined) {
      Filter_Modems.encode(message.modems, writer.uint32(10).fork()).ldelim();
    }
    if (message.freeTextSearch !== undefined) {
      writer.uint32(66).string(message.freeTextSearch);
    }
    if (message.onlyActive !== undefined) {
      writer.uint32(32).bool(message.onlyActive);
    }
    if (message.activatedIn !== undefined) {
      TimeRange.encode(message.activatedIn, writer.uint32(42).fork()).ldelim();
    }
    if (message.withLastMessageIn !== undefined) {
      TimeRange.encode(message.withLastMessageIn, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.healthLevels) {
      writer.uint32(146).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.lifecycles) {
      writer.int32(modem_LifecycleToNumber(v));
    }
    writer.ldelim();
    if (message.transfers !== undefined) {
      ModemSelection_Transfers.encode(message.transfers, writer.uint32(90).fork()).ldelim();
    }
    writer.uint32(130).fork();
    for (const v of message.includeTypes) {
      writer.int32(modem_TypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(154).fork();
    for (const v of message.excludeTypes) {
      writer.int32(modem_TypeToNumber(v));
    }
    writer.ldelim();
    if (message.deviceTypes !== undefined) {
      Filter_DeviceTypes.encode(message.deviceTypes, writer.uint32(178).fork()).ldelim();
    }
    if (message.sensorBrands !== undefined) {
      Filter_SensorBrands.encode(message.sensorBrands, writer.uint32(186).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      Filter_ModemIdentifiers.encode(message.identifiers, writer.uint32(194).fork()).ldelim();
    }
    if (message.onlyGateways !== undefined) {
      writer.uint32(96).bool(message.onlyGateways);
    }
    if (message.onlyHasExternalDeviceIds !== undefined) {
      writer.uint32(104).bool(message.onlyHasExternalDeviceIds);
    }
    if (message.connectedToGateways !== undefined) {
      Filter_Modems.encode(message.connectedToGateways, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.externalDeviceIds) {
      writer.uint32(122).string(v!);
    }
    if (message.filterByTags !== undefined) {
      TagSelection.encode(message.filterByTags, writer.uint32(18).fork()).ldelim();
    }
    switch (message.peripheralSelection?.$case) {
      case "peripherals":
        ModemSelection_Peripherals.encode(message.peripheralSelection.peripherals, writer.uint32(162).fork()).ldelim();
        break;
      case "onlyWithoutPeripheral":
        writer.uint32(168).bool(message.peripheralSelection.onlyWithoutPeripheral);
        break;
    }
    if (message.onlyConnectedToGateway !== undefined) {
      writer.uint32(200).bool(message.onlyConnectedToGateway);
    }
    if (message.notConnectedToGateway !== undefined) {
      writer.uint32(208).bool(message.notConnectedToGateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.freeTextSearch = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.onlyActive = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.activatedIn = TimeRange.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.withLastMessageIn = TimeRange.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.healthLevels.push(reader.string());
          continue;
        case 10:
          if (tag === 80) {
            message.lifecycles.push(modem_LifecycleFromJSON(reader.int32()));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.lifecycles.push(modem_LifecycleFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.transfers = ModemSelection_Transfers.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag === 128) {
            message.includeTypes.push(modem_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.includeTypes.push(modem_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 19:
          if (tag === 152) {
            message.excludeTypes.push(modem_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.excludeTypes.push(modem_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.deviceTypes = Filter_DeviceTypes.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.sensorBrands = Filter_SensorBrands.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.identifiers = Filter_ModemIdentifiers.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.onlyGateways = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.onlyHasExternalDeviceIds = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.connectedToGateways = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.externalDeviceIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filterByTags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.peripheralSelection = {
            $case: "peripherals",
            peripherals: ModemSelection_Peripherals.decode(reader, reader.uint32()),
          };
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.peripheralSelection = { $case: "onlyWithoutPeripheral", onlyWithoutPeripheral: reader.bool() };
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.onlyConnectedToGateway = reader.bool();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.notConnectedToGateway = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection>): ModemSelection {
    return ModemSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemSelection>): ModemSelection {
    const message = createBaseModemSelection();
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? Filter_Modems.fromPartial(object.modems)
      : undefined;
    message.freeTextSearch = object.freeTextSearch ?? undefined;
    message.onlyActive = object.onlyActive ?? undefined;
    message.activatedIn = (object.activatedIn !== undefined && object.activatedIn !== null)
      ? TimeRange.fromPartial(object.activatedIn)
      : undefined;
    message.withLastMessageIn = (object.withLastMessageIn !== undefined && object.withLastMessageIn !== null)
      ? TimeRange.fromPartial(object.withLastMessageIn)
      : undefined;
    message.healthLevels = object.healthLevels?.map((e) => e) || [];
    message.lifecycles = object.lifecycles?.map((e) => e) || [];
    message.transfers = (object.transfers !== undefined && object.transfers !== null)
      ? ModemSelection_Transfers.fromPartial(object.transfers)
      : undefined;
    message.includeTypes = object.includeTypes?.map((e) => e) || [];
    message.excludeTypes = object.excludeTypes?.map((e) => e) || [];
    message.deviceTypes = (object.deviceTypes !== undefined && object.deviceTypes !== null)
      ? Filter_DeviceTypes.fromPartial(object.deviceTypes)
      : undefined;
    message.sensorBrands = (object.sensorBrands !== undefined && object.sensorBrands !== null)
      ? Filter_SensorBrands.fromPartial(object.sensorBrands)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? Filter_ModemIdentifiers.fromPartial(object.identifiers)
      : undefined;
    message.onlyGateways = object.onlyGateways ?? undefined;
    message.onlyHasExternalDeviceIds = object.onlyHasExternalDeviceIds ?? undefined;
    message.connectedToGateways = (object.connectedToGateways !== undefined && object.connectedToGateways !== null)
      ? Filter_Modems.fromPartial(object.connectedToGateways)
      : undefined;
    message.externalDeviceIds = object.externalDeviceIds?.map((e) => e) || [];
    message.filterByTags = (object.filterByTags !== undefined && object.filterByTags !== null)
      ? TagSelection.fromPartial(object.filterByTags)
      : undefined;
    if (
      object.peripheralSelection?.$case === "peripherals" &&
      object.peripheralSelection?.peripherals !== undefined &&
      object.peripheralSelection?.peripherals !== null
    ) {
      message.peripheralSelection = {
        $case: "peripherals",
        peripherals: ModemSelection_Peripherals.fromPartial(object.peripheralSelection.peripherals),
      };
    }
    if (
      object.peripheralSelection?.$case === "onlyWithoutPeripheral" &&
      object.peripheralSelection?.onlyWithoutPeripheral !== undefined &&
      object.peripheralSelection?.onlyWithoutPeripheral !== null
    ) {
      message.peripheralSelection = {
        $case: "onlyWithoutPeripheral",
        onlyWithoutPeripheral: object.peripheralSelection.onlyWithoutPeripheral,
      };
    }
    message.onlyConnectedToGateway = object.onlyConnectedToGateway ?? undefined;
    message.notConnectedToGateway = object.notConnectedToGateway ?? undefined;
    return message;
  },
};

function createBaseModemSelection_Transfers(): ModemSelection_Transfers {
  return { transfersIdentifiers: [] };
}

export const ModemSelection_Transfers = {
  encode(message: ModemSelection_Transfers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.transfersIdentifiers) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection_Transfers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection_Transfers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transfersIdentifiers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection_Transfers>): ModemSelection_Transfers {
    return ModemSelection_Transfers.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemSelection_Transfers>): ModemSelection_Transfers {
    const message = createBaseModemSelection_Transfers();
    message.transfersIdentifiers = object.transfersIdentifiers?.map((e) => e) || [];
    return message;
  },
};

function createBaseModemSelection_Peripherals(): ModemSelection_Peripherals {
  return { includeAnd: {}, exclude: {} };
}

export const ModemSelection_Peripherals = {
  encode(message: ModemSelection_Peripherals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.includeAnd).forEach(([key, value]) => {
      ModemSelection_Peripherals_IncludeAndEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    Object.entries(message.exclude).forEach(([key, value]) => {
      ModemSelection_Peripherals_ExcludeEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection_Peripherals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection_Peripherals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ModemSelection_Peripherals_IncludeAndEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.includeAnd[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ModemSelection_Peripherals_ExcludeEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.exclude[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection_Peripherals>): ModemSelection_Peripherals {
    return ModemSelection_Peripherals.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemSelection_Peripherals>): ModemSelection_Peripherals {
    const message = createBaseModemSelection_Peripherals();
    message.includeAnd = Object.entries(object.includeAnd ?? {}).reduce<
      { [key: string]: ModemSelection_Peripherals_OneOfValues }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ModemSelection_Peripherals_OneOfValues.fromPartial(value);
      }
      return acc;
    }, {});
    message.exclude = Object.entries(object.exclude ?? {}).reduce<
      { [key: string]: ModemSelection_Peripherals_OneOfValues }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ModemSelection_Peripherals_OneOfValues.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseModemSelection_Peripherals_OneOfValues(): ModemSelection_Peripherals_OneOfValues {
  return { value: [] };
}

export const ModemSelection_Peripherals_OneOfValues = {
  encode(message: ModemSelection_Peripherals_OneOfValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.value) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection_Peripherals_OneOfValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection_Peripherals_OneOfValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection_Peripherals_OneOfValues>): ModemSelection_Peripherals_OneOfValues {
    return ModemSelection_Peripherals_OneOfValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemSelection_Peripherals_OneOfValues>): ModemSelection_Peripherals_OneOfValues {
    const message = createBaseModemSelection_Peripherals_OneOfValues();
    message.value = object.value?.map((e) => e) || [];
    return message;
  },
};

function createBaseModemSelection_Peripherals_IncludeAndEntry(): ModemSelection_Peripherals_IncludeAndEntry {
  return { key: "", value: undefined };
}

export const ModemSelection_Peripherals_IncludeAndEntry = {
  encode(message: ModemSelection_Peripherals_IncludeAndEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ModemSelection_Peripherals_OneOfValues.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection_Peripherals_IncludeAndEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection_Peripherals_IncludeAndEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ModemSelection_Peripherals_OneOfValues.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection_Peripherals_IncludeAndEntry>): ModemSelection_Peripherals_IncludeAndEntry {
    return ModemSelection_Peripherals_IncludeAndEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemSelection_Peripherals_IncludeAndEntry>,
  ): ModemSelection_Peripherals_IncludeAndEntry {
    const message = createBaseModemSelection_Peripherals_IncludeAndEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ModemSelection_Peripherals_OneOfValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseModemSelection_Peripherals_ExcludeEntry(): ModemSelection_Peripherals_ExcludeEntry {
  return { key: "", value: undefined };
}

export const ModemSelection_Peripherals_ExcludeEntry = {
  encode(message: ModemSelection_Peripherals_ExcludeEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ModemSelection_Peripherals_OneOfValues.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemSelection_Peripherals_ExcludeEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemSelection_Peripherals_ExcludeEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ModemSelection_Peripherals_OneOfValues.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemSelection_Peripherals_ExcludeEntry>): ModemSelection_Peripherals_ExcludeEntry {
    return ModemSelection_Peripherals_ExcludeEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemSelection_Peripherals_ExcludeEntry>): ModemSelection_Peripherals_ExcludeEntry {
    const message = createBaseModemSelection_Peripherals_ExcludeEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ModemSelection_Peripherals_OneOfValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseModemMessage(): ModemMessage {
  return {
    messageId: 0,
    modemNumber: "",
    modemName: "",
    modemIdentifier: "",
    sentAt: undefined,
    receivedAt: undefined,
    location: undefined,
    body: new Uint8Array(0),
    bodyBytes: undefined,
    bodyParsed: [],
    bodyParsedSuccessfully: false,
    sources: [],
    isTest: false,
    isGatewayMessage: false,
    viaGatewayMessage: 0,
    metadata: undefined,
    bodyFields: undefined,
    tags: [],
    tagNames: [],
  };
}

export const ModemMessage = {
  encode(message: ModemMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageId !== 0) {
      writer.uint32(56).uint64(message.messageId);
    }
    if (message.modemNumber !== "") {
      writer.uint32(10).string(message.modemNumber);
    }
    if (message.modemName !== "") {
      writer.uint32(138).string(message.modemName);
    }
    if (message.modemIdentifier !== "") {
      writer.uint32(146).string(message.modemIdentifier);
    }
    if (message.sentAt !== undefined) {
      Timestamp.encode(message.sentAt, writer.uint32(26).fork()).ldelim();
    }
    if (message.receivedAt !== undefined) {
      Timestamp.encode(message.receivedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(34).fork()).ldelim();
    }
    if (message.body.length !== 0) {
      writer.uint32(42).bytes(message.body);
    }
    if (message.bodyBytes !== undefined) {
      BytesOrHex.encode(message.bodyBytes, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.bodyParsed) {
      ModemMessage_ParsedBody.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.bodyParsedSuccessfully !== false) {
      writer.uint32(96).bool(message.bodyParsedSuccessfully);
    }
    writer.uint32(82).fork();
    for (const v of message.sources) {
      writer.int32(modemMessage_SourceToNumber(v));
    }
    writer.ldelim();
    if (message.isTest !== false) {
      writer.uint32(72).bool(message.isTest);
    }
    if (message.isGatewayMessage !== false) {
      writer.uint32(104).bool(message.isGatewayMessage);
    }
    if (message.viaGatewayMessage !== 0) {
      writer.uint32(112).uint64(message.viaGatewayMessage);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(122).fork()).ldelim();
    }
    if (message.bodyFields !== undefined) {
      Struct.encode(Struct.wrap(message.bodyFields), writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.tagNames) {
      writer.uint32(162).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag !== 56) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.modemName = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.modemIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.receivedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.body = reader.bytes();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.bodyBytes = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bodyParsed.push(ModemMessage_ParsedBody.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.bodyParsedSuccessfully = reader.bool();
          continue;
        case 10:
          if (tag === 80) {
            message.sources.push(modemMessage_SourceFromJSON(reader.int32()));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sources.push(modemMessage_SourceFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isTest = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isGatewayMessage = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.viaGatewayMessage = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.bodyFields = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.tagNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessage>): ModemMessage {
    return ModemMessage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessage>): ModemMessage {
    const message = createBaseModemMessage();
    message.messageId = object.messageId ?? 0;
    message.modemNumber = object.modemNumber ?? "";
    message.modemName = object.modemName ?? "";
    message.modemIdentifier = object.modemIdentifier ?? "";
    message.sentAt = (object.sentAt !== undefined && object.sentAt !== null)
      ? Timestamp.fromPartial(object.sentAt)
      : undefined;
    message.receivedAt = (object.receivedAt !== undefined && object.receivedAt !== null)
      ? Timestamp.fromPartial(object.receivedAt)
      : undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.body = object.body ?? new Uint8Array(0);
    message.bodyBytes = (object.bodyBytes !== undefined && object.bodyBytes !== null)
      ? BytesOrHex.fromPartial(object.bodyBytes)
      : undefined;
    message.bodyParsed = object.bodyParsed?.map((e) => ModemMessage_ParsedBody.fromPartial(e)) || [];
    message.bodyParsedSuccessfully = object.bodyParsedSuccessfully ?? false;
    message.sources = object.sources?.map((e) => e) || [];
    message.isTest = object.isTest ?? false;
    message.isGatewayMessage = object.isGatewayMessage ?? false;
    message.viaGatewayMessage = object.viaGatewayMessage ?? 0;
    message.metadata = object.metadata ?? undefined;
    message.bodyFields = object.bodyFields ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.tagNames = object.tagNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseModemMessage_ParsedBody(): ModemMessage_ParsedBody {
  return { parserId: 0, parserIdentifier: "", parserName: "", parsed: undefined };
}

export const ModemMessage_ParsedBody = {
  encode(message: ModemMessage_ParsedBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parserId !== 0) {
      writer.uint32(8).int32(message.parserId);
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(50).string(message.parserIdentifier);
    }
    if (message.parserName !== "") {
      writer.uint32(18).string(message.parserName);
    }
    switch (message.parsed?.$case) {
      case "error":
        writer.uint32(34).string(message.parsed.error);
        break;
      case "result":
        Struct.encode(Struct.wrap(message.parsed.result), writer.uint32(42).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessage_ParsedBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessage_ParsedBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.parserId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parserName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.parsed = { $case: "error", error: reader.string() };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.parsed = { $case: "result", result: Struct.unwrap(Struct.decode(reader, reader.uint32())) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessage_ParsedBody>): ModemMessage_ParsedBody {
    return ModemMessage_ParsedBody.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessage_ParsedBody>): ModemMessage_ParsedBody {
    const message = createBaseModemMessage_ParsedBody();
    message.parserId = object.parserId ?? 0;
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.parserName = object.parserName ?? "";
    if (object.parsed?.$case === "error" && object.parsed?.error !== undefined && object.parsed?.error !== null) {
      message.parsed = { $case: "error", error: object.parsed.error };
    }
    if (object.parsed?.$case === "result" && object.parsed?.result !== undefined && object.parsed?.result !== null) {
      message.parsed = { $case: "result", result: object.parsed.result };
    }
    return message;
  },
};

function createBaseModemMessageSelection(): ModemMessageSelection {
  return { modems: undefined, modemSelection: undefined, timeRange: undefined, filterBySources: undefined };
}

export const ModemMessageSelection = {
  encode(message: ModemMessageSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modems !== undefined) {
      Filter_Modems.encode(message.modems, writer.uint32(10).fork()).ldelim();
    }
    if (message.modemSelection !== undefined) {
      ModemSelection.encode(message.modemSelection, writer.uint32(42).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterBySources !== undefined) {
      ModemMessageSelection_ModemMessageSourceFilter.encode(message.filterBySources, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modemSelection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterBySources = ModemMessageSelection_ModemMessageSourceFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessageSelection>): ModemMessageSelection {
    return ModemMessageSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessageSelection>): ModemMessageSelection {
    const message = createBaseModemMessageSelection();
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? Filter_Modems.fromPartial(object.modems)
      : undefined;
    message.modemSelection = (object.modemSelection !== undefined && object.modemSelection !== null)
      ? ModemSelection.fromPartial(object.modemSelection)
      : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.filterBySources = (object.filterBySources !== undefined && object.filterBySources !== null)
      ? ModemMessageSelection_ModemMessageSourceFilter.fromPartial(object.filterBySources)
      : undefined;
    return message;
  },
};

function createBaseModemMessageSelection_ModemMessageSourceFilter(): ModemMessageSelection_ModemMessageSourceFilter {
  return { include: [], exclude: [] };
}

export const ModemMessageSelection_ModemMessageSourceFilter = {
  encode(
    message: ModemMessageSelection_ModemMessageSourceFilter,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int32(modemMessage_SourceToNumber(v));
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int32(modemMessage_SourceToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageSelection_ModemMessageSourceFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageSelection_ModemMessageSourceFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(modemMessage_SourceFromJSON(reader.int32()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(modemMessage_SourceFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(modemMessage_SourceFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(modemMessage_SourceFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemMessageSelection_ModemMessageSourceFilter>,
  ): ModemMessageSelection_ModemMessageSourceFilter {
    return ModemMessageSelection_ModemMessageSourceFilter.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemMessageSelection_ModemMessageSourceFilter>,
  ): ModemMessageSelection_ModemMessageSourceFilter {
    const message = createBaseModemMessageSelection_ModemMessageSourceFilter();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetModemRequest(): GetModemRequest {
  return { where: undefined, modemNumber: "", childOrganizations: undefined };
}

export const GetModemRequest = {
  encode(message: GetModemRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.where?.$case) {
      case "organization":
        writer.uint32(10).string(message.where.organization);
        break;
      case "inOrganizations":
        OrganizationSelection.encode(message.where.inOrganizations, writer.uint32(26).fork()).ldelim();
        break;
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetModemRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetModemRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.where = { $case: "organization", organization: reader.string() };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.where = {
            $case: "inOrganizations",
            inOrganizations: OrganizationSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetModemRequest>): GetModemRequest {
    return GetModemRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetModemRequest>): GetModemRequest {
    const message = createBaseGetModemRequest();
    if (
      object.where?.$case === "organization" &&
      object.where?.organization !== undefined &&
      object.where?.organization !== null
    ) {
      message.where = { $case: "organization", organization: object.where.organization };
    }
    if (
      object.where?.$case === "inOrganizations" &&
      object.where?.inOrganizations !== undefined &&
      object.where?.inOrganizations !== null
    ) {
      message.where = {
        $case: "inOrganizations",
        inOrganizations: OrganizationSelection.fromPartial(object.where.inOrganizations),
      };
    }
    message.modemNumber = object.modemNumber ?? "";
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseListModemsRequest(): ListModemsRequest {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sortBy: [],
    childOrganizations: undefined,
    locationSelection: undefined,
    includeMissingGroupParents: undefined,
  };
}

export const ListModemsRequest = {
  encode(message: ListModemsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortBy) {
      writer.int32(listModemsRequest_SortToNumber(v));
    }
    writer.ldelim();
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(66).fork()).ldelim();
    }
    if (message.locationSelection !== undefined) {
      LocationSelection.encode(message.locationSelection, writer.uint32(74).fork()).ldelim();
    }
    if (message.includeMissingGroupParents !== undefined) {
      writer.uint32(80).bool(message.includeMissingGroupParents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortBy.push(listModemsRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortBy.push(listModemsRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.locationSelection = LocationSelection.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.includeMissingGroupParents = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsRequest>): ListModemsRequest {
    return ListModemsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemsRequest>): ListModemsRequest {
    const message = createBaseListModemsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sortBy = object.sortBy?.map((e) => e) || [];
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    message.locationSelection = (object.locationSelection !== undefined && object.locationSelection !== null)
      ? LocationSelection.fromPartial(object.locationSelection)
      : undefined;
    message.includeMissingGroupParents = object.includeMissingGroupParents ?? undefined;
    return message;
  },
};

function createBaseListModemsRequest_Response(): ListModemsRequest_Response {
  return { modems: [], request: undefined, pagination: undefined, sortedBy: [], groupParents: [] };
}

export const ListModemsRequest_Response = {
  encode(message: ListModemsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      Modem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortedBy) {
      writer.int32(listModemsRequest_SortToNumber(v));
    }
    writer.ldelim();
    for (const v of message.groupParents) {
      Modem.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(Modem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListModemsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortedBy.push(listModemsRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortedBy.push(listModemsRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupParents.push(Modem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsRequest_Response>): ListModemsRequest_Response {
    return ListModemsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemsRequest_Response>): ListModemsRequest_Response {
    const message = createBaseListModemsRequest_Response();
    message.modems = object.modems?.map((e) => Modem.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemsRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.sortedBy = object.sortedBy?.map((e) => e) || [];
    message.groupParents = object.groupParents?.map((e) => Modem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListModemsGrouped(): ListModemsGrouped {
  return {};
}

export const ListModemsGrouped = {
  encode(_: ListModemsGrouped, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsGrouped {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsGrouped();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsGrouped>): ListModemsGrouped {
    return ListModemsGrouped.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListModemsGrouped>): ListModemsGrouped {
    const message = createBaseListModemsGrouped();
    return message;
  },
};

function createBaseListModemsGrouped_Request(): ListModemsGrouped_Request {
  return { groupContent: undefined, groups: undefined, allowAnyGroupParent: undefined };
}

export const ListModemsGrouped_Request = {
  encode(message: ListModemsGrouped_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupContent !== undefined) {
      ListModemsRequest.encode(message.groupContent, writer.uint32(10).fork()).ldelim();
    }
    if (message.groups !== undefined) {
      ListModemsRequest.encode(message.groups, writer.uint32(18).fork()).ldelim();
    }
    if (message.allowAnyGroupParent !== undefined) {
      writer.uint32(24).bool(message.allowAnyGroupParent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsGrouped_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsGrouped_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupContent = ListModemsRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groups = ListModemsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowAnyGroupParent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsGrouped_Request>): ListModemsGrouped_Request {
    return ListModemsGrouped_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemsGrouped_Request>): ListModemsGrouped_Request {
    const message = createBaseListModemsGrouped_Request();
    message.groupContent = (object.groupContent !== undefined && object.groupContent !== null)
      ? ListModemsRequest.fromPartial(object.groupContent)
      : undefined;
    message.groups = (object.groups !== undefined && object.groups !== null)
      ? ListModemsRequest.fromPartial(object.groups)
      : undefined;
    message.allowAnyGroupParent = object.allowAnyGroupParent ?? undefined;
    return message;
  },
};

function createBaseListModemsGrouped_Response(): ListModemsGrouped_Response {
  return { groups: [], request: undefined, pagination: undefined, sortedBy: [], total: undefined };
}

export const ListModemsGrouped_Response = {
  encode(message: ListModemsGrouped_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      ListModemsRequest_Response.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemsGrouped_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortedBy) {
      writer.int32(listModemsRequest_SortToNumber(v));
    }
    writer.ldelim();
    if (message.total !== undefined) {
      ListModemsGrouped_Response_Total.encode(message.total, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsGrouped_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsGrouped_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(ListModemsRequest_Response.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListModemsGrouped_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortedBy.push(listModemsRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortedBy.push(listModemsRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.total = ListModemsGrouped_Response_Total.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsGrouped_Response>): ListModemsGrouped_Response {
    return ListModemsGrouped_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemsGrouped_Response>): ListModemsGrouped_Response {
    const message = createBaseListModemsGrouped_Response();
    message.groups = object.groups?.map((e) => ListModemsRequest_Response.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemsGrouped_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.sortedBy = object.sortedBy?.map((e) => e) || [];
    message.total = (object.total !== undefined && object.total !== null)
      ? ListModemsGrouped_Response_Total.fromPartial(object.total)
      : undefined;
    return message;
  },
};

function createBaseListModemsGrouped_Response_Total(): ListModemsGrouped_Response_Total {
  return { groups: 0, content: 0, all: 0 };
}

export const ListModemsGrouped_Response_Total = {
  encode(message: ListModemsGrouped_Response_Total, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groups !== 0) {
      writer.uint32(8).uint32(message.groups);
    }
    if (message.content !== 0) {
      writer.uint32(16).uint32(message.content);
    }
    if (message.all !== 0) {
      writer.uint32(24).uint32(message.all);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemsGrouped_Response_Total {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemsGrouped_Response_Total();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groups = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.content = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.all = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemsGrouped_Response_Total>): ListModemsGrouped_Response_Total {
    return ListModemsGrouped_Response_Total.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemsGrouped_Response_Total>): ListModemsGrouped_Response_Total {
    const message = createBaseListModemsGrouped_Response_Total();
    message.groups = object.groups ?? 0;
    message.content = object.content ?? 0;
    message.all = object.all ?? 0;
    return message;
  },
};

function createBaseListModemMessagesRequest(): ListModemMessagesRequest {
  return { organization: "", selection: undefined, pagination: undefined };
}

export const ListModemMessagesRequest = {
  encode(message: ListModemMessagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemMessageSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemMessageSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemMessagesRequest>): ListModemMessagesRequest {
    return ListModemMessagesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemMessagesRequest>): ListModemMessagesRequest {
    const message = createBaseListModemMessagesRequest();
    message.organization = object.organization ?? "";
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemMessageSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListModemMessagesRequest_Response(): ListModemMessagesRequest_Response {
  return { messages: [], request: undefined, pagination: undefined };
}

export const ListModemMessagesRequest_Response = {
  encode(message: ListModemMessagesRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.messages) {
      ModemMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemMessagesRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessagesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessagesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messages.push(ModemMessage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListModemMessagesRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemMessagesRequest_Response>): ListModemMessagesRequest_Response {
    return ListModemMessagesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemMessagesRequest_Response>): ListModemMessagesRequest_Response {
    const message = createBaseListModemMessagesRequest_Response();
    message.messages = object.messages?.map((e) => ModemMessage.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemMessagesRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateModem(): CreateModem {
  return {};
}

export const CreateModem = {
  encode(_: CreateModem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModem>): CreateModem {
    return CreateModem.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CreateModem>): CreateModem {
    const message = createBaseCreateModem();
    return message;
  },
};

function createBaseCreateModem_Request(): CreateModem_Request {
  return {
    organization: undefined,
    amount: 0,
    names: [],
    externalDeviceIdentifiers: [],
    lifecycle: undefined,
    technical: undefined,
    peripherals: {},
    notes: undefined,
    initialLocation: undefined,
    tags: undefined,
    includeModemsInResponse: undefined,
    includeVerificationCode: undefined,
  };
}

export const CreateModem_Request = {
  encode(message: CreateModem_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.amount !== 0) {
      writer.uint32(16).uint32(message.amount);
    }
    for (const v of message.names) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.externalDeviceIdentifiers) {
      writer.uint32(34).string(v!);
    }
    if (message.lifecycle !== undefined) {
      writer.uint32(40).int32(modem_LifecycleToNumber(message.lifecycle));
    }
    if (message.technical !== undefined) {
      Modem_TechnicalData.encode(message.technical, writer.uint32(50).fork()).ldelim();
    }
    Object.entries(message.peripherals).forEach(([key, value]) => {
      CreateModem_Request_PeripheralsEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
    });
    if (message.notes !== undefined) {
      writer.uint32(66).string(message.notes);
    }
    switch (message.initialLocation?.$case) {
      case "location":
        Location.encode(message.initialLocation.location, writer.uint32(74).fork()).ldelim();
        break;
      case "randomLocationInArea":
        Area.encode(message.initialLocation.randomLocationInArea, writer.uint32(82).fork()).ldelim();
        break;
    }
    if (message.tags !== undefined) {
      UpdateTagsForItem.encode(message.tags, writer.uint32(90).fork()).ldelim();
    }
    if (message.includeModemsInResponse !== undefined) {
      writer.uint32(96).bool(message.includeModemsInResponse);
    }
    if (message.includeVerificationCode !== undefined) {
      writer.uint32(104).bool(message.includeVerificationCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModem_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModem_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.amount = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.externalDeviceIdentifiers.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lifecycle = modem_LifecycleFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.technical = Modem_TechnicalData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = CreateModem_Request_PeripheralsEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.peripherals[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.initialLocation = { $case: "location", location: Location.decode(reader, reader.uint32()) };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.initialLocation = {
            $case: "randomLocationInArea",
            randomLocationInArea: Area.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tags = UpdateTagsForItem.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.includeModemsInResponse = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.includeVerificationCode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModem_Request>): CreateModem_Request {
    return CreateModem_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModem_Request>): CreateModem_Request {
    const message = createBaseCreateModem_Request();
    message.organization = object.organization ?? undefined;
    message.amount = object.amount ?? 0;
    message.names = object.names?.map((e) => e) || [];
    message.externalDeviceIdentifiers = object.externalDeviceIdentifiers?.map((e) => e) || [];
    message.lifecycle = object.lifecycle ?? undefined;
    message.technical = (object.technical !== undefined && object.technical !== null)
      ? Modem_TechnicalData.fromPartial(object.technical)
      : undefined;
    message.peripherals = Object.entries(object.peripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.notes = object.notes ?? undefined;
    if (
      object.initialLocation?.$case === "location" &&
      object.initialLocation?.location !== undefined &&
      object.initialLocation?.location !== null
    ) {
      message.initialLocation = { $case: "location", location: Location.fromPartial(object.initialLocation.location) };
    }
    if (
      object.initialLocation?.$case === "randomLocationInArea" &&
      object.initialLocation?.randomLocationInArea !== undefined &&
      object.initialLocation?.randomLocationInArea !== null
    ) {
      message.initialLocation = {
        $case: "randomLocationInArea",
        randomLocationInArea: Area.fromPartial(object.initialLocation.randomLocationInArea),
      };
    }
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? UpdateTagsForItem.fromPartial(object.tags)
      : undefined;
    message.includeModemsInResponse = object.includeModemsInResponse ?? undefined;
    message.includeVerificationCode = object.includeVerificationCode ?? undefined;
    return message;
  },
};

function createBaseCreateModem_Request_PeripheralsEntry(): CreateModem_Request_PeripheralsEntry {
  return { key: "", value: "" };
}

export const CreateModem_Request_PeripheralsEntry = {
  encode(message: CreateModem_Request_PeripheralsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModem_Request_PeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModem_Request_PeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModem_Request_PeripheralsEntry>): CreateModem_Request_PeripheralsEntry {
    return CreateModem_Request_PeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModem_Request_PeripheralsEntry>): CreateModem_Request_PeripheralsEntry {
    const message = createBaseCreateModem_Request_PeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCreateModem_Response(): CreateModem_Response {
  return { modems: [], request: undefined };
}

export const CreateModem_Response = {
  encode(message: CreateModem_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      CreateModem_Response_CreatedModem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      CreateModem_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModem_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModem_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(CreateModem_Response_CreatedModem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = CreateModem_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModem_Response>): CreateModem_Response {
    return CreateModem_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModem_Response>): CreateModem_Response {
    const message = createBaseCreateModem_Response();
    message.modems = object.modems?.map((e) => CreateModem_Response_CreatedModem.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? CreateModem_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCreateModem_Response_CreatedModem(): CreateModem_Response_CreatedModem {
  return { modemNumber: "", verificationCode: "", modem: undefined };
}

export const CreateModem_Response_CreatedModem = {
  encode(message: CreateModem_Response_CreatedModem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modemNumber !== "") {
      writer.uint32(10).string(message.modemNumber);
    }
    if (message.verificationCode !== "") {
      writer.uint32(18).string(message.verificationCode);
    }
    if (message.modem !== undefined) {
      Modem.encode(message.modem, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModem_Response_CreatedModem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModem_Response_CreatedModem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.verificationCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modem = Modem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModem_Response_CreatedModem>): CreateModem_Response_CreatedModem {
    return CreateModem_Response_CreatedModem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModem_Response_CreatedModem>): CreateModem_Response_CreatedModem {
    const message = createBaseCreateModem_Response_CreatedModem();
    message.modemNumber = object.modemNumber ?? "";
    message.verificationCode = object.verificationCode ?? "";
    message.modem = (object.modem !== undefined && object.modem !== null) ? Modem.fromPartial(object.modem) : undefined;
    return message;
  },
};

function createBaseUpdateModemTagsRequest(): UpdateModemTagsRequest {
  return { organization: undefined, update: undefined, selection: undefined, pagination: undefined };
}

export const UpdateModemTagsRequest = {
  encode(message: UpdateModemTagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.update !== undefined) {
      UpdateTagsForItem.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    if (message.selection !== undefined) {
      ModemSelection.encode(message.selection, writer.uint32(42).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemTagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemTagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateTagsForItem.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.selection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemTagsRequest>): UpdateModemTagsRequest {
    return UpdateModemTagsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemTagsRequest>): UpdateModemTagsRequest {
    const message = createBaseUpdateModemTagsRequest();
    message.organization = object.organization ?? undefined;
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateTagsForItem.fromPartial(object.update)
      : undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemTagsRequest_Response(): UpdateModemTagsRequest_Response {
  return { modems: [], request: undefined, pagination: undefined };
}

export const UpdateModemTagsRequest_Response = {
  encode(message: UpdateModemTagsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      Modem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UpdateModemTagsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemTagsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemTagsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(Modem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UpdateModemTagsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemTagsRequest_Response>): UpdateModemTagsRequest_Response {
    return UpdateModemTagsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemTagsRequest_Response>): UpdateModemTagsRequest_Response {
    const message = createBaseUpdateModemTagsRequest_Response();
    message.modems = object.modems?.map((e) => Modem.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? UpdateModemTagsRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseTagCount(): TagCount {
  return { tag: undefined, modems: 0 };
}

export const TagCount = {
  encode(message: TagCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== undefined) {
      Tag.encode(message.tag, writer.uint32(10).fork()).ldelim();
    }
    if (message.modems !== 0) {
      writer.uint32(16).uint32(message.modems);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = Tag.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.modems = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagCount>): TagCount {
    return TagCount.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagCount>): TagCount {
    const message = createBaseTagCount();
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.modems = object.modems ?? 0;
    return message;
  },
};

function createBaseTagCount_Request(): TagCount_Request {
  return { organization: undefined, selection: undefined, tagSelection: undefined };
}

export const TagCount_Request = {
  encode(message: TagCount_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.tagSelection !== undefined) {
      TagSelection.encode(message.tagSelection, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagCount_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagCount_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tagSelection = TagSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagCount_Request>): TagCount_Request {
    return TagCount_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagCount_Request>): TagCount_Request {
    const message = createBaseTagCount_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemSelection.fromPartial(object.selection)
      : undefined;
    message.tagSelection = (object.tagSelection !== undefined && object.tagSelection !== null)
      ? TagSelection.fromPartial(object.tagSelection)
      : undefined;
    return message;
  },
};

function createBaseTagCount_Response(): TagCount_Response {
  return { tags: [], request: undefined };
}

export const TagCount_Response = {
  encode(message: TagCount_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      TagCount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      TagCount_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagCount_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagCount_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(TagCount.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = TagCount_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagCount_Response>): TagCount_Response {
    return TagCount_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagCount_Response>): TagCount_Response {
    const message = createBaseTagCount_Response();
    message.tags = object.tags?.map((e) => TagCount.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? TagCount_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/**
 * The core of the Hiber system, modems are the network nodes that send information and user data.
 * This service contains calls to list and manage them, as well as list their messages.
 */
export type ModemServiceDefinition = typeof ModemServiceDefinition;
export const ModemServiceDefinition = {
  name: "ModemService",
  fullName: "hiber.modem.ModemService",
  methods: {
    /** Get a single modem. */
    get: {
      name: "Get",
      requestType: GetModemRequest,
      requestStream: false,
      responseType: Modem,
      responseStream: false,
      options: {},
    },
    /** Create new modem(s). This is only available if your organization can create modems. */
    create: {
      name: "Create",
      requestType: CreateModem_Request,
      requestStream: false,
      responseType: CreateModem_Response,
      responseStream: false,
      options: {},
    },
    /** List the modems in your organization, and, optionally, its child organizations. */
    list: {
      name: "List",
      requestType: ListModemsRequest,
      requestStream: false,
      responseType: ListModemsRequest_Response,
      responseStream: false,
      options: {},
    },
    /**
     * List the modems in your organization, and, optionally, its child organizations, grouped by dependency.
     * For example, a modem that sends it messages through a gateway (like Hilo) would be grouped under that gateway.
     */
    grouped: {
      name: "Grouped",
      requestType: ListModemsGrouped_Request,
      requestStream: false,
      responseType: ListModemsGrouped_Response,
      responseStream: false,
      options: {},
    },
    /**
     * List messages for the selected modems.
     *
     * @deprecated
     */
    messages: {
      name: "Messages",
      requestType: ListModemMessagesRequest,
      requestStream: false,
      responseType: ListModemMessagesRequest_Response,
      responseStream: false,
      options: {},
    },
    /** Add, remove and create new tags for the selected modems. */
    updateTags: {
      name: "UpdateTags",
      requestType: UpdateModemTagsRequest,
      requestStream: false,
      responseType: UpdateModemTagsRequest_Response,
      responseStream: false,
      options: {},
    },
    tagCount: {
      name: "TagCount",
      requestType: TagCount_Request,
      requestStream: false,
      responseType: TagCount_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
